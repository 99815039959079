import { environment } from "src/environments/environment";


export class Settings {
    // public apiBaseUrl = 'http://localhost:8088/dreptullaviataapi';
    // public apiBaseUrl = 'http://dreptullaviata.aiso.ro/api';

    //#region Authenticate
    public authenticateUrl = environment.api.baseUrl + '/users/login/';
    //
    //#region Users
    public usersUrl = environment.api.baseUrl + '/user-portal/getAll.php';
    public userAddUrl = environment.api.baseUrl + '/user-portal/addUser.php';
    //

    //#region Images
    public imagesUrl = environment.api.baseUrl + '/Images.php'; // '/images-portal/getAll.php';
    public deleteimage = environment.api.baseUrl + '/controllers/ImageController.php?';
    //

    //#region Pages
    public pagesUrl = environment.api.baseUrl + '/Pages.php'; // '/images-portal/getAll.php';
    //
    //#region Pages
    public teamUrl = environment.api.baseUrl + '/controllers/TeamController.php'; // '/images-portal/getAll.php';
    //
}
