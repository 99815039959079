import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from './frontoffice/services/SpinnerService';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Dreptullaviata';
  private subscription: Subscription;
  public isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(
    private loaderService: SpinnerService) {
  }

  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
