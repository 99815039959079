<div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
  <div fxFlex="25%" class="sec1" fxFlex.xs="100%" style="min-width: 300px;">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
        </mat-card-title>
        <mat-card-subtitle>
          Adminstrare pagini
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-nav-list>
          <mat-list-item *ngFor="let page of pages">
            <!-- [routerLinkActiveOptions]="{exact:true}" -->
            <!-- <a matLine [routerLink]="[{ outlets: { 'sidebar': [page.URL] } }]">{{ page.Title }}</a> -->
            <!-- <a matLine [routerLink]="[{ outlets: { 'sidebar': [page.URL] } }]">{{ page.Title }}</a> -->
            <a matLine [routerLink]="[page.URL]" routerLinkActive="active">
              <span [routerLink]="[page.URL]">
                {{ page.Name }}
              </span>
            </a>

            <button mat-icon-button (click)="viewContent(page.URL)">
              <mat-icon>info</mat-icon>
            </button>
            <mat-divider></mat-divider>

          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-nav-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="75%" class="sec2" fxFlex.xs="100%">
    <div class="item item-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>

  <div fxFlex="300px" class="sec1" fxFlex.xs="100%" style="min-width: 300px;">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div style="margin-top: 8px">
            <button pButton type="button" label="" (click)="expandAll()" style="margin-right: .25em; height: 30px;">
              <mat-icon>expand_more</mat-icon>
            </button>
            <button pButton type="button" label="" (click)="collapseAll()" style="height: 30px;">
              <mat-icon>expand_less</mat-icon>
            </button>
          </div>
        </mat-card-title>
        <mat-card-subtitle>
          Expand/Collapse
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <p-tree #expandingTree [value]="nodes" selectionMode="single" [loading]="loading"
          (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [style]="{'width':'100%'}">
        </p-tree>
      </mat-card-content>
    </mat-card>


  </div>
  <div fxFlex="85%" class="sec2" fxFlex.xs="100%">
    <div class="item item-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->