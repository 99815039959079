<div flex layout="row" style="background-color: #ececec; min-height: 700%">

  <mat-card class="d-mat-card-general" flex>
    <div class="d-loader" layout="row" fxLayoutAling="center center" *ngIf="isLoading| async">
      <!-- <mat-spinner class="spinner"></mat-spinner> -->
      <!-- <mat-progress-spinner mode="indeterminate" [diameter]="70"></mat-progress-spinner> -->
      <button mat-fab style="z-index: 9999;" color="warn">
        <mat-icon class="icon">favorite</mat-icon>
      </button>
    </div>
    <router-outlet>
    </router-outlet>
  </mat-card>
  <button [ngClass]="{'show-scroll': showScroll}" (click)="scrollToTop()" class="top-button scroll-to-top">
    <i class="material-icons">
      keyboard_arrow_up
    </i>
  </button>
</div>