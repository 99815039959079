<form #form="ngForm" class="description-card container-wrapper">



  <app-command-bar>
    <mat-icon>videocam</mat-icon>
    <h1>Articole video</h1>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <div class="basic-container">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let video of videos; let i = index" [expanded]="i === 0" (opened)="i === 0">
            <mat-expansion-panel-header>
              <h3 className="item__title"><a [href]="video.URL">
                  {{video.Name}}
                </a></h3>

            </mat-expansion-panel-header>
            <!-- {{video.Description | keepHtml}} -->
            <div [innerHTML]="video.Description | keepHtml"></div>

            <iframe className="embed-responsive-item" [class.thumbnail]="thumbnail" width="800" height="600"
              frameborder="0" [src]="video.SafeURL" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!-- <iframe width="1140" height="641" src="https://www.youtube.com/embed/lI0jkMh83Sk" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""></iframe> -->
            <mat-divider></mat-divider>
            <br />
          </mat-expansion-panel>

        </mat-accordion>
      </div>

    </mat-card-content>
  </mat-card>
</form>
<br />
<form #form="ngForm" class="description-card container-wrapper">

  <app-command-bar>
    <mat-icon>photo_camera</mat-icon>
    <h1>Articole media</h1>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <div class="basic-container">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let item of media; let i = index" [expanded]="i === 0" (opened)="i === 0">
            <mat-expansion-panel-header>
              <h3 className="item__title"><a [href]="item.URL">
                  {{item.Name}}
                </a></h3>
            </mat-expansion-panel-header>
            {{item.Description}}
            <!-- <iframe className="embed-responsive-item" [class.thumbnail]="thumbnail" width="800" height="600"
                frameborder="0" [src]="video.URL | safeUrl" frameborder="0" webkitallowfullscreen mozallowfullscreen
                allowfullscreen></iframe> -->

            <mat-divider></mat-divider>
            <br />
          </mat-expansion-panel>

        </mat-accordion>
      </div>

    </mat-card-content>

  </mat-card>
</form>