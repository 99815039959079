<mat-card class="description-card container-wrapper">
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <!--  -->
    <!-- <div ng-view="">
      <div [innerHTML]="editorContent | keepHtml"></div>
    </div> -->

    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill style="word-break: break-word;">
      <div fxFlex="60" class="sec1" fxFlex.xs="60">
        <form class="contactForm" #contactForm="ngForm">
          <div class="bounds full-width" style="max-width: 70%; margin-left: 15%; margin-right: 15%">

            <!-- <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Nume de contact</mat-label>
                <input matInput type="text" placeholder="Nume de contact" [(ngModel)]="contactname" name="contactname"
                  required minlength="3">
                <mat-icon matPrefix>contacts</mat-icon>
              </mat-form-field>
            </div>
            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Email" [(ngModel)]="adresa" name="adresa" required>
                <mat-icon matPrefix>contact_mail</mat-icon>
              </mat-form-field>
            </div>
            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Telefon</mat-label>
                <input matInput type="phone" placeholder="Telefon" [(ngModel)]="telefon" name="telefon" required>
                <mat-icon matPrefix>contact_phone</mat-icon>
              </mat-form-field>
            </div>

            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
              <mat-form-field appearance="outline" style="width: 100%;" matTextareaAutosize matAutosizeMinRows="5"
                matAutosizeMaxRows="18">
                <textarea matInput placeholder="Mesaj" [(ngModel)]="message" name="message" required maxlength="256"
                  #messageText value=""></textarea>
                <mat-icon matPrefix>message</mat-icon>
                <mat-hint align="end">{{messageText.value.length}} / 256</mat-hint>
              </mat-form-field>
            </div>

            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
              <mat-card-actions>
                <button mat-raised-button (click)="saveContact()" [disabled]="!contactForm.form.valid">Trimite</button>
              </mat-card-actions>
            </div> -->


          </div>

        </form>
      </div>
      <div fxFlex="40" class="sec2" fxFlex.xs="40" [innerHTML]="editorContent | keepHtml">
      </div>
    </div>
  </mat-card-content>



</mat-card>
<!-- <mat-form-field class="example-full-width">
                          <input matInput #message maxlength="256" placeholder="Message">
                          <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint>
                          <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                        </mat-form-field> -->




<!-- 
<section class="cmspage mtb-40">
    <div class="container">
      <div class="page-desc" [hidden]="submitted">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <h1>Contact</h1>
            <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
              <div class="form-group">
                <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control"
                  placeholder="Name" required #name="ngModel">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control"
                  placeholder="E-Mail" required email #email="ngModel">
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                  <div *ngIf="email.errors.required">Email is required.</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" class="form-control"
                  placeholder="Phone">
              </div>
              <div class="form-group">
                <textarea name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control"
                  placeholder="Message" required #message="ngModel"></textarea>
                <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                  <div *ngIf="message.errors.required">Message is required.</div>
                </div>
              </div>
              <div class="form-group">
                <button [disabled]="!contactForm.form.valid" class="btn btn-success">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="service-error" *ngIf="error">
        <h1>{{error.errorTitle}}</h1>
        <h3>{{error.errorDesc}}</h3>
      </div>
      <div [hidden]="!submitted" class="contact-message">
        <div *ngIf="model.id" class="contact-success">
          <h2 class="success">Success!</h2>
          <h4>Contact form has been successfully submitted.</h4>
          <br />
          <button (click)="gotoHome()" class="btn btn-info">Go to Home</button>
        </div>
      </div>
    </div>
  </section> -->

<!-- 
            <mat-checkbox [checked]="isDeleted">Is deleted?</mat-checkbox>

            <mat-form-field>
              <mat-label>Date</mat-label>
              <input [value]="date.value" matInput [matDatepicker]="myPicker" placeholder="date">
              <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
              <mat-datepicker #myPicker></mat-datepicker>
            </mat-form-field>
            <mat-label>Gender</mat-label>
            <mat-radio-group [(value)]="contactGender">
              <mat-radio-button value="male">Male</mat-radio-button>
              <mat-radio-button value="female">Female</mat-radio-button>
            </mat-radio-group>

            <mat-form-field>
              <mat-label>Source of Contact</mat-label>
              <mat-select [(value)]="contactSource" placeholder="Source of contact">
                <mat-option value="email">Email</mat-option>
                <mat-option value="website">Website</mat-option>
                <mat-option value="direct">Direct</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-card-actions>
              <button mat-raised-button (click)="saveContact()" color="primary">Save Contact</button>
            </mat-card-actions> -->


<!-- 
<p>
  <mat-form-field appearance="legacy">
    <mat-label>Legacy form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="standard">
    <mat-label>Standard form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill">
    <mat-label>Fill form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="outline">
    <mat-label>Outline form field</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>Hint</mat-hint>
  </mat-form-field>
</p>
<p>
  contact works!
</p> -->