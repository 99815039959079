import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/frontoffice/services/UserService';
import { SpinnerService } from 'src/app/frontoffice/services/SpinnerService';
import { DataSource } from '@angular/cdk/table';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/entities/models/user.model';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit, OnDestroy, AfterViewInit {
  dataSource = new UserDataSource(this.userService, this.spinnerService);
  displayedColumns = ['Id', 'FirstName', 'LastName', 'Email', 'Password'];
  constructor(private userService: UserService, private spinnerService: SpinnerService) { }
  ngOnInit() {
  }
  ngAfterViewInit(): void {
  }
  ngOnDestroy(): void {
  }
}

export class UserDataSource extends DataSource<any> {
  constructor(private userService: UserService, private spinnerService: SpinnerService) {
    super();
  }
  connect(): Observable<User[]> {
    const result = this.userService.getUser();
    return result;
  }
  disconnect() { }
}
