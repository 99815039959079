import { Component, OnInit } from '@angular/core';
import { PageModel } from '../../../core/entities/models/page.model';
import { UserService } from '../../services/UserService';
import { SpinnerService } from '../../services/SpinnerService';
import { PageService } from '../../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { VideoArticleService } from 'src/app/core/services/video-article.service';
import { VideoArticleModel } from 'src/app/core/entities/models/video-article.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MediaArticleService } from 'src/app/core/services/media-article.service';
import { MediaArticleModel } from 'src/app/core/entities/models/media-article.model';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  public success: string;
  public error: string;
  public editorContent: string;
  public articlePage: PageModel;

  public videos: VideoArticleModel[] = [];
  public media: MediaArticleModel[] = [];


  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
    , private videoArticleService: VideoArticleService
    , private mediaArticleService: MediaArticleService

    , private sanitizer: DomSanitizer
  ) { }

  public ngOnInit(): void {
    this.loadData();
  }
  public loadData(): void {
    // about page
    this.pageService.findOne(Pages.About).subscribe(
      res => {
        this.editorContent = res.Text1.toString();
      });

    this.videoArticleService.findAll().subscribe(res => {
      this.videos = res;
      this.videos.map(video => {
        video.SafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(video.URL);
      })
    })
    this.mediaArticleService.findAll().subscribe(res => {
      this.media = res;
      this.media.map(media => {
        media.SafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(media.URL);
      })
    })

  }
  public setUrl(url: string): SafeResourceUrl {
    const safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return safeSrc;
  }


}
