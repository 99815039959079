import { Component, OnInit } from '@angular/core';
import { PageModel } from '../../core/entities/models/page.model';
import { UserService } from '../services/UserService';
import { SpinnerService } from '../services/SpinnerService';
import { PageService } from '../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  public editorContent: string;
  private centerPage: PageModel;

  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.findOne(Pages.Donate).subscribe(
      res => {
        this.editorContent = res.Text1.toString();
      });
  }
}
