import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonService } from 'src/app/frontoffice/services/CommonService';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class AlertService {
    // public config = new MatSnackBarConfig();

    public config: MatSnackBarConfig = {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
    }
    public message: string = 'Snack Bar opened.';
    public actionButtonLabel: string = 'Retry';
    public action: boolean = true;
    public setAutoHide: boolean = true;
    public autoHide: number = 2000;
    public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    public verticalPosition: MatSnackBarVerticalPosition = 'bottom';


    constructor(protected _http: HttpClient, private snackBar: MatSnackBar) {
    }

    success(content?, action?, duration?) {
        this.config['panelClass'] = ['success', 'notification']

        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = "succes-style";
        // {
        //     duration: this.autoHide,
        //         panelClass: ["succes-style"]
        // }

        let sb = this.snackBar.open(content, action, this.config);
        sb.onAction().subscribe(() => {
            sb.dismiss();
        });
    }
    error(content?, action?, duration?) {
        this.config['panelClass'] = ['error', 'notification']
        // {
        //     duration: duration,
        //         panelClass: ["error-style"]
        // }
        let sb = this.snackBar.open(content, action, this.config);
        sb.onAction().subscribe(() => {
            sb.dismiss();
        });
    }
}
