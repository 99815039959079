<!-- dialog-box.component.html -->
<!-- <h1 mat-dialog-title><strong>{{action}}</strong></h1> -->
<app-base-section-bar>
    <h1 mat-dialog-title>Partener</h1>
</app-base-section-bar>
<div mat-dialog-content>
    <div *ngIf="data">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start" fxFlex="100" fxFill>
            <div fxLayout="column" fxLayoutGap="10px" fxFlex="100" fxFill>
                <mat-form-field class="full-width">
                    <mat-label>Nume companie</mat-label>
                    <input matInput placeholder="Nume" name="fname" [(ngModel)]="model.Name" #nameRef="ngModel" ngModel
                        required>
                    <mat-icon matSuffix>article</mat-icon>
                    <mat-error *ngIf="nameRef.hasError('required')">
                        Numele companiei este camp <strong>obligatoriu</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Telefon</mat-label>
                    <input matInput placeholder="Telefon" name="fname" [(ngModel)]="model.Phone" #nameRef="ngModel"
                        ngModel>
                    <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Website</mat-label>
                    <input matInput placeholder="Website" name="fname" [(ngModel)]="model.Website" #nameRef="ngModel"
                        ngModel>
                    <mat-icon matSuffix>web</mat-icon>
                </mat-form-field>


            </div>
        </div>

    </div>
</div>
<div mat-dialog-actions fxLayout fxLayoutAlign="flex-end">
    <button mat-button (click)="doAction()" mat-flat-button color="primary">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>