import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { TeamModel } from '../entities/models/team.model';
import { Observable } from 'rxjs';

@Injectable()
export class UploadService extends DataService<TeamModel, string> {
    header: any;

    public headers: HttpHeaders = new HttpHeaders();


    constructor(protected _http: HttpClient) {
        super(_http, `${environment.api.baseUrl}/upload`);
        const headerSettings: { [name: string]: string | string[]; } = {};
        this.header = new HttpHeaders(headerSettings);
    }

    public upload(formData, prefixFolder): Observable<any> {
        return this._http.post<any>(`${environment.api.baseUrl}/upload/` + prefixFolder, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
}