import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../core/entities/models/user.model';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonService } from './CommonService';
import { of } from 'rxjs';

import { Router } from '@angular/router';
import { DataService } from '../../core/services/data.service';
import { BaseModel } from '../../core/entities/models/Base.model';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends DataService<BaseModel, string> {
    public currentUser: Observable<User>;
    public isAuthenticated = new BehaviorSubject<boolean>(false);
    public currentUserSubject: BehaviorSubject<User>;


    jwtHelper: JwtHelperService;

    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
    }

    constructor(protected _http: HttpClient, private commonService: CommonService
        , private router: Router
    ) {
        super(_http, `${environment.api.baseUrl}/users`);

        // this.currentUserSubject = new BehaviorSubject<User>(new User());
        // // (localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : '');
        // this.currentUser = this.currentUserSubject.asObservable();
        // // this.jwtHelper = jwtHelperService;

        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('access_token')));
        this.currentUser = this.currentUserSubject.asObservable();

    }

    // constructor(private http: HttpClient
    //     , private spinnerService: SpinnerService
    //     , private commonService: CommonService
    //     , private router: Router
    // ) {
    //     this.currentUserSubject = new BehaviorSubject<User>(new User());
    //     // (localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : '');
    //     this.currentUser = this.currentUserSubject.asObservable();
    //     // this.jwtHelper = jwtHelperService;
    // }

    public get currentUserValue(): User {
        const t = localStorage.getItem('access_token');
        if (t && t.length > 0) {
            this.loggedIn.next(true);

            const myRawToken = t;
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(myRawToken);

            const newUser = new User();
            newUser.Username = decodedToken['user'];
            newUser.IsAdmin = decodedToken['isAdmin'];

            this.currentUserSubject.next(newUser);
        }
        return this.currentUserSubject.value;
    }

    // public async login(userModel: AuthUserModel): Promise<Observable<any>> {
    //     const md5 = new Md5();
    //     userModel.Password = (md5.appendStr(userModel.Password).end()).toString();
    //     return (await this.authService.login(userModel));
    // }

    login(username: string, password: string) {

        const t = localStorage.getItem('access_token');
        if (t) {
            const myRawToken = t;
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(myRawToken);
            if (t && t.length > 0) {
                this.loggedIn.next(true);

                const newUser = new User();
                newUser.Email = decodedToken['user'];
                newUser.IsAdmin = decodedToken['isAdmin'];

                this.currentUserSubject.next(newUser);
            }
            const locations = of(t);

            return locations;
        } else {
            this.loggedIn.next(false);
            const helper = new JwtHelperService();
            const md5 = new Md5();
            password = (md5.appendStr(password).end()).toString();
            // return this._http.get<any>(`${environment.api.baseUrl}/authusers/login/` + model.Nickname + `/` + model.Password);

            return this._http.get<any>(`${environment.api.baseUrl}/users/login/` + username + `/` + password)
                .pipe(map((token: any) => {
                    const myRawToken = token;
                    const decodedToken = helper.decodeToken(myRawToken);
                    if (token && token.length > 0) {
                        this.loggedIn.next(true);

                        localStorage.setItem('access_token', JSON.stringify(myRawToken));
                        const newUser = new User();
                        newUser.Email = decodedToken['user'];
                        newUser.IsAdmin = decodedToken['isAdmin'];

                        this.currentUserSubject.next(newUser);
                    }

                    return token;
                }), catchError(this.commonService.handleError));
            ;

        }


    }

    logout() {
        this.loggedIn.next(false);

        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        this.currentUserSubject.next(null); // {4}
        this.router.navigate(['/login']);
    }
}
