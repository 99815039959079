import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, throwError, } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { User } from '../../core/entities/models/user.model';

import { SpinnerService } from '../services/SpinnerService';
import { Settings } from './../../core/app.settings';
import { CommonService } from './CommonService';


@Injectable()
export class UserService implements OnInit, OnDestroy {
  private settings = new Settings();
  private show = false;

  private serviceUrl = 'https://jsonplaceholder.typicode.com/users';

  // private serviceUrl = 'https://reqres.in/api/users?delay=3';
  private subscription: Subscription;
  isLogged: any;
  users: User[];

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  constructor(private http: HttpClient
    , private spinnerService: SpinnerService
    , private commonService: CommonService
  ) { }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();

  }
  //   getCarsSmall() {
  //     return this.http.get<any>('assets/data/cars-small.json')
  //         .toPromise()
  //         .then(res => <Car[]> res.data)
  //         .then(data => data);
  // }

  login(username: string, password: string) {
    return this.http.get<User[]>(this.settings.authenticateUrl, { params: { username: username, password: password } })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        // && user.token
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', user[0].Email);
        }
        return user;
        // return user;
      }), catchError(this.commonService.handleError));

  }


  getUser(): Observable<User[]> {
    return this.http.get<User[]>(this.settings.usersUrl)
      .pipe(
        catchError(this.commonService.handleError)
      )

      .pipe(map(result => result),         // Log when response observable either completes or errors
        finalize(() => {
        }));
  }
  extractData(res: Response): any {
  }
}
