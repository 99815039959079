import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/frontoffice/services/ImagesService';
import { ImageModel } from 'src/app/frontoffice/models/ImageModel';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  public editorContent = ClassicEditor;
  public contentLoaded = false;
  public images = [];
  public homePage: PageModel;
  public pagesEnum = Pages;

  constructor(private imagesService: ImagesService
    , private pageService: PageService
    , private alertService: AlertService

  ) {
  }

  public ngOnInit() {
    this.loadData();
  }

  public loadData() {
    this.imagesService.findAll(Pages.Home.toString())
      .subscribe(
        res => {
          this.images = res;
        });
    this.pageService.findOne(Pages.Home)
      .subscribe(
        res => {
          this.homePage = res;
          this.contentLoaded = true;
        });
  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.homePage.Motto = editor.getData().replace("<p>", "").replace("</p>", "");
      if (!this.homePage.Motto || this.homePage.Motto.length === 0) {
        this.homePage.Motto = " ";
      }
    }
  }


  public updatePage(event) {
    this.pageService.update(Pages.Home, this.homePage)
      .subscribe((res) => {
        this.homePage = res;
        this.alertService.success('Pagina a fost updatata cu success!');
        this.loadData();
      },
        (err) => {
          if (err) {
            this.alertService.error(err)
          }
        }
      );
  }

  addImage(event, image: ImageModel) {
    if (event.files) {
      const fileToUpload = event.files[0];
      const imageToAdd = new ImageModel();
      imageToAdd.Path = event.files[0].name;
      imageToAdd.PageId = Pages.Home;
      // input.append('file', fileToUpload);
      // const formData: FormData = new FormData();
      // formData.append('file', fileToUpload, fileToUpload.name);

      this.imagesService.addImage(imageToAdd, event.files[0])
        .subscribe(
          (res: ImageModel[]) => {
            // Update the list of cars
            this.images = res;

            // Inform the user
            this.alertService.success('Created successfully');

            // Reset the form
            // f.reset();
          },
          (err) => this.alertService.error(err.error)

        );
    }

  }

  deleteImage(event, image: ImageModel) {

    // this.toastrService.confirm('Are you sure?', { onOk: () => { console.log('ok') }, onCancel: () => { console.log('cancel') } })


    this.imagesService.deleteImage(image.Id)
      .subscribe(
        (res: ImageModel[]) => {
          this.images = res;
          this.alertService.success('Deleted successfully');
        },
        (err) => this.alertService.error(err.error)

      );
    const imageDel = this.images.indexOf(image);
    this.images.splice(imageDel, 1);
  }
  confirm(event, image: ImageModel) {
    // this.confirmationService.confirm({
    //   message: 'Vreti sa stergeti?',
    //   header: 'Confirmare',
    //   icon: 'pi pi-exclamation-triangle',
    //   acceptLabel: 'DA',
    //   rejectLabel: 'NU',
    //   accept: () => {
    //     this.deleteImage(event, image);
    //     this.msgs = [{ severity: 'info', summary: 'Confirmare', detail: 'Inregistrarea a fost stearsa!' }];
    //   },
    //   reject: () => {
    //     this.msgs = [{ severity: 'info', summary: 'Anulare', detail: 'Operatiunea a fost anulata!' }];
    //   }
    // });
  }
  onSubmitTemplateBased() {
    // console.log(this.vm);
  }
}
