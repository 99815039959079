<form #form="ngForm" class="description-card container-wrapper">
  <app-command-bar>
    <h1>Despre noi</h1>
  </app-command-bar>
  <mat-card>

    <mat-card-header>

    </mat-card-header>
    <mat-card-content class="d-mat-card-content-30vh">
      <!--  -->

      <div *ngIf="aboutPage?.Content" [innerHTML]="aboutPage.Content | keepHtml"></div>
    </mat-card-content>
    <!-- <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
  </mat-card-actions> -->
  </mat-card>
</form>
<br />
<form #form="ngForm" class="description-card container-wrapper">
  <app-command-bar>
    <h1>Echipa noastra</h1>
  </app-command-bar>
  <mat-card>

    <mat-card-header>
      <!-- <mat-card-title fxLayoutAlign="center center">Echipa noastra</mat-card-title> -->

    </mat-card-header>
    <mat-card-content>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center center" fxFlex="100" fxFill>
        <div fxLayout="column" fxLayout.xs="row" *ngFor="let team of teams">
          <app-teams-card [data]="team"></app-teams-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>