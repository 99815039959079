<div fxLayout="column" fxLayoutGap="10px" fxFlex="100" fxFill>
    <mat-card *ngIf="data" class="mat-elevation-z8">
        <mat-card-header>
            <mat-card-title>
                <div fxLayout="row" fxLayoutGap="10px" fxFlex="100" fxFill>
                    <div fxLayout="column">
                        <mat-icon>favorite_border</mat-icon>
                    </div>
                    <div fxLayout="column">
                        <mat-icon>thumb_up</mat-icon>
                    </div>
                    <div fxLayout="column">
                        {{data.Name}}
                    </div>
                </div>
            </mat-card-title>
            <mat-card-subtitle>

            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
</div>


<!-- <mat-card class="example-card">
    <mat-card-content>
        <img mat-card-image src="https://image4.geekbuying.com/ggo_pic/2016-08-30/2016083001543551umb5a0d.jpg"
            alt="Photo of a Shiba Inu" style="width:100px; float:left; height:100px">
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-subtitle>Headphones</mat-card-subtitle>
            <mat-card-title>Xiaomi Redmi Note 4</mat-card-title>
        </mat-card-header>
        <br>
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>$225</mat-card-title>
        </mat-card-header>


    </mat-card-content>

</mat-card> -->