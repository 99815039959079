import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.scss']
})
export class AdminFooterComponent implements OnInit {

  public editorContent = ClassicEditor;
  public editorText1 = ClassicEditor;
  public editorText2 = ClassicEditor;
  public editorText3 = ClassicEditor;

  public editorContentLoaded = false;
  public editorText1Loaded = false;
  public editorText2Loaded = false;
  public editorText3Loaded = false;

  public footerPage: PageModel = new PageModel();

  constructor(private pageService: PageService, private alertService: AlertService) {
  }

  ngOnInit() {
    this.getData();
  }
  getData() {
    this.pageService.findOne(Pages.Footer).subscribe(
      (res: PageModel) => {
        this.footerPage = res;
        this.editorContentLoaded = true;
        this.editorText1Loaded = true;
        this.editorText2Loaded = true;
        this.editorText3Loaded = true;
      });
  }


  public onText1Change({ editor }: ChangeEvent) {
    if (editor) {
      this.footerPage.Text1 = editor.getData();
      this.footerPage.Text1 = this.footerPage.Text1.replace("image", "image image-footer")
      if (!this.footerPage.Text1 || this.footerPage.Text1.length === 0) {
        this.footerPage.Text1 = " ";
      }
    }
  }
  public onText2Change({ editor }: ChangeEvent) {
    if (editor) {
      this.footerPage.Text2 = editor.getData();
      if (!this.footerPage.Text2 || this.footerPage.Text2.length === 0) {
        this.footerPage.Text2 = " ";
      }
    }
  }
  public onText3Change({ editor }: ChangeEvent) {
    if (editor) {
      this.footerPage.Text3 = editor.getData();
      if (!this.footerPage.Text3 || this.footerPage.Text3.length === 0) {
        this.footerPage.Text3 = " ";
      }
    }
  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.footerPage.Content = editor.getData();
      if (!this.footerPage.Content || this.footerPage.Content.length === 0) {
        this.footerPage.Content = " ";
      }
    }
  }

  public updatePage(event) {
    const footerPage = new PageModel();


    this.pageService.update(Pages.Footer, this.footerPage)
      .subscribe((res) => {
        this.alertService.success('Pagina a fost updatata cu success!');
      },
        (err) => this.alertService.error(err));
  }
  isRequired() {
    return true;
  }

}
