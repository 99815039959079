
export const environment = {
  production: true,
  domain: 'http://localhost:4300',
  api: {
    // baseUrl: 'https://diplomacrm.aiso.ro',
    baseUrl: 'https://api.dreptullaviata.ro/',//to change

  }
};
