<app-command-bar>
    <h1>Echipa</h1>
</app-command-bar>

<app-command-bar>
    <button mat-button color="primary" class="crm-command-bar-button" (click)="createNew()">
        <mat-icon>add</mat-icon>
        {{ 'Nou' }}
    </button>
</app-command-bar>
<app-datatable [dataSource]="dataSource" [displayedColumns]="columns" [tableConfig]="tableConfig"
    (selected)="selected($event)" (filter)="onFilter($event)" (sort)="onSort($event)" (rowClicked)="rowClicked($event)"
    (columnFiltered)="onColumnFiltered($event)" (rowRightClicked)="rowRightClicked($event)" [data]="teams"
    [editDialog]="editDialog" [service]="teamsService">
</app-datatable>