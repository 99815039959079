import { Component, OnInit, HostListener } from '@angular/core';
import { ImagesService } from '../services/ImagesService';
import { SpinnerService } from '../services/SpinnerService';
import { PageService } from '../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { PageModel } from '../../core/entities/models/page.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
@HostListener('window:resize', ['$event'])
export class HomeComponent implements OnInit {
  public pictures = [];
  public page: PageModel;
  constructor(private imagesService: ImagesService
    , private spinnerService: SpinnerService
    , private pageService: PageService
  ) {
  }

  ngOnInit() {
    this.spinnerService.show();
    this.getData();
    this.spinnerService.hide();
  }

  getData() {
    this.imagesService.findAll().subscribe(
      res => {
        this.pictures = res;
      });

    this.pageService.findOne(Pages.Home).subscribe(
      res => {
        this.page = res;
      }
    );

  }
  onResize() {

  }
}
