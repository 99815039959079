<form #f="ngForm" name="theForm">
  <app-command-bar>
    <h1>Contact</h1>
  </app-command-bar>
  <app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="updatePage($event)">
      <mat-icon>save</mat-icon>
      {{ 'Save' }}
    </button>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="contactPage.Text1 || editorTextLoaded">
        <ckeditor [editor]="editorText" [data]="contactPage.Text1" (change)="onTextChange($event)"></ckeditor>
      </div>
    </mat-card-content>
  </mat-card>
</form>