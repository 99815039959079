import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { HtmlContentHelper } from '../shared/HtmlContentHelper';
import { Pages } from 'src/app/core/shared/StandardEnum';

import { AlertService } from 'src/app/core/services/alert.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

@Component({
  selector: 'app-admin-about',
  templateUrl: './admin-about.component.html',
  styleUrls: ['./admin-about.component.scss'],
  providers: [HtmlContentHelper]
})
export class AdminAboutComponent implements OnInit {
  curTab: any = 0;
  public editorContent = ClassicEditor;
  public editorText = ClassicEditor;




  public helper: HtmlContentHelper;
  public aboutContentLoaded = false;
  public aboutTextLoaded = false;

  public aboutPage: PageModel;
  constructor(private pageService: PageService
    , public helperComponent: HtmlContentHelper
    , public alertService: AlertService
  ) {
    this.helper = helperComponent;
  }

  public ngOnInit() {
    this.loadData();
  }

  public loadData() {
    // about page
    this.pageService.findOne(Pages.About).subscribe(res => {
      this.aboutPage = res;
      this.aboutContentLoaded = true;
      this.aboutTextLoaded = true;
    });
  }

  public onTextChange({ editor }: ChangeEvent) {
    if (editor) {
      this.aboutPage.Text1 = editor.getData();
      if (!this.aboutPage.Text1 || this.aboutPage.Text1.length === 0) {
        this.aboutPage.Text1 = " ";
      }
    }
  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.aboutPage.Content = editor.getData();
      if (!this.aboutPage.Content || this.aboutPage.Content.length === 0) {
        this.aboutPage.Content = " ";
      }
    }
  }

  public updatePage(event) {
    this.pageService.update(Pages.About, this.aboutPage)
      .subscribe((res) => {
        this.aboutPage = res;
        this.alertService.success('Pagina a fost updatata cu success!');
        this.loadData();
      },
        (err) => {
          if (err) {
            this.alertService.error(err)
          }
        }
      );
  }
  public validateChange(event, content: string) {
    const html = event.htmlValue;
    if (html.includes('<iframe')) {
      content = this.helper.getSafeUrl(html);
      this.aboutPage.Text1 = content;
    }
  }
}




//   public config = {
//   // toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
//   // plugins: ["youtube"],
//   // plugins: [MediaEmbed],
//   toolbar: [
//     'undo',
//     'redo',
//     '|',
//     'heading',
//     'fontFamily',
//     'fontSize',
//     '|',
//     'bold',
//     'italic',
//     'underline',
//     'fontColor',
//     'fontBackgroundColor',
//     'highlight',
//     '|',
//     'link',
//     'CKFinder',
//     'imageUpload',
//     'mediaEmbed',
//     '|',
//     'alignment',
//     'bulletedList',
//     'numberedList',
//     '|',
//     'indent',
//     'outdent',
//     '|',
//     'insertTable',
//     'blockQuote',
//     'specialCharacters',
//     'mediaEmbed'
//   ],
//   mediaEmbed: {
//     previewsInData: true
//   },
//   // mediaEmbed: {
//   //   // configuration...
//   // },
//   heading: {
//     options: [
//       { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
//       { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
//       { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
//       { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }

//     ]
//   },
//   // toolbar: ['mediaEmbed', ... ]
//   //     mediaEmbed: {
//   //   // configuration...
//   // }
//   // extraPlugins: ['youtube']
// }