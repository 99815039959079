import { Component, OnInit } from '@angular/core';
import { PageModel } from '../../core/entities/models/page.model';
import { UserService } from '../services/UserService';
import { SpinnerService } from '../services/SpinnerService';
import { PageService } from '../services/PagesServices';
import { ContactModel } from '../../frontoffice/models/ContactModel';
import { Router } from '@angular/router';
import { SendEmailService } from '../services/SendEmailService';
import { Pages } from 'src/app/core/shared/StandardEnum';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public model = new ContactModel();
  public submitted = false;
  public error: {};
  public editorContent: string;
  public centerPage: PageModel;

  constructor(

    private router: Router
    , private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
    , private sendEmailService: SendEmailService
  ) { }

  public ngOnInit(): void {
    this.getData();
  }
  public getData(): void {
    // about page
    this.pageService.findOne(Pages.Contact).subscribe(
      res => {
        this.editorContent = res.Text1.toString();
      });
  }

  public onSubmit() {
    this.submitted = true;
    return this.sendEmailService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }

  public gotoHome(): void {
    this.router.navigate(['/']);
  }

}
