<div>
  <!-- <div [froalaEditor] [(froalaModel)]="ckeditorContent"></div> -->
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="Id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.Id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="FirstName">
      <mat-header-cell *matHeaderCellDef> FirstName </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.FirstName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="LastName">
      <mat-header-cell *matHeaderCellDef> LastName </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.LastName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Email">
      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.Email}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Password">
      <mat-header-cell *matHeaderCellDef> Password </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.Password}} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

</div>