<app-base-section-bar>
    <h1 mat-dialog-title>Coleg</h1>
</app-base-section-bar>
<div mat-dialog-content>
    <div *ngIf="data">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start" fxFlex="100" fxFill>
            <div fxLayout="column" fxLayoutGap="10px" fxFlex="100" fxFill>
                <mat-form-field class="full-width">
                    <mat-label>Nume</mat-label>
                    <input matInput placeholder="Nume" name="fname" [(ngModel)]="model.Name" #nameRef="ngModel" ngModel
                        required>
                    <mat-icon matSuffix>article</mat-icon>
                    <mat-error *ngIf="nameRef.hasError('required')">
                        Numele este camp <strong>obligatoriu</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <mat-label>Poza</mat-label>
                    <input matInput placeholder="Picture" name="fname" [(ngModel)]="model.Picture" #nameRef="ngModel"
                        ngModel>
                    <mat-icon matSuffix>web</mat-icon>
                </mat-form-field>

                <!-- <mat-form-field class="full-width">
                    <mat-label>Descriere</mat-label>
                    <input matInput placeholder="Descriere" name="fDescription" [(ngModel)]="model.Description"
                        #nameRef="ngModel" ngModel>
                    <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field> -->
                <ckeditor [editor]="editorContent" [(data)]="model.Description" (change)="onContentChange($event)">
                </ckeditor>
            </div>
        </div>

    </div>
</div>


<div mat-dialog-actions fxLayout fxLayoutAlign="flex-end">
    <button mat-button (click)="doAction()" mat-flat-button color="primary">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>