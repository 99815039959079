<footer class="md-scroll-shrink footer" style="min-height: 400px" style="">
  <div class="bounds" style="max-width: 70%; margin-left: 15%; margin-right: 15%">
    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
      <div *ngIf="editorValueCol1" fxLayout="column" fxLayoutAlign="center center" fxFlex="33" class="sec1"
        fxFlex.xs="30" [innerHTML]="editorValueCol1 | keepHtml">
      </div>
      <div *ngIf="editorValueCol2" fxLayout="column" fxFlex="33" class="sec2" fxFlex.xs="35"
        [innerHTML]="editorValueCol2 | keepHtml">
      </div>
      <div *ngIf="editorValueCol3" fxLayout="column" fxFlex="33" class="sec3" fxFlex.xs="35"
        [innerHTML]="editorValueCol3 | keepHtml">
      </div>
    </div>
    <span style="text-align: center">
      &copy; 2018 - {{year| date:'yyyy'}}
    </span>
  </div>
</footer>