
export class User {
    Id: number;
    FirstName: string;
    LastName: string;
    Username: string;
    Email: string;
    Password: string;
    token?: string;
    IsAdmin: number;
}
