import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NodeService {
    // result: TreeNode[];
    // constructor(private http: HttpClient) { }
    // getFiles(): Observable<TreeNode> {
    //     return this.http.get<TreeNode>('../../assets/data/files.json');
    //         // .toPromise()
    //         // .then(
    //         //     (res) => {
    //         //         return res;
    //         //     })
    //         // .map(res => res)
    //         // .catch((e) => { });
    // }
}
