import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-article-dialog',
  templateUrl: './admin-article-dialog.component.html',
  styleUrls: ['./admin-article-dialog.component.scss']
})
export class AdminArticleDialogComponent implements OnInit {
  public action: string;
  public model: any;
  public editorContent = ClassicEditor;


  constructor(
    public dialogRef: MatDialogRef<AdminArticleDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = { ...data.model };
    this.action = data.action;
  }
  public ngOnInit(): void {

  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.model.Description = editor.getData().replace("<p>", "").replace("</p>", "");
      if (!this.model.Description || this.model.Description.length === 0) {
        this.model.Description = " ";
      }
    }
  }
  public doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.model });
  }

  public closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
