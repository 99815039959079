export class StandardEnum {

}

export enum Pages {
    Home = 1,
    Footer = 2,
    About = 3,
    Projects = 4,
    Center = 5,
    Christmas = 6,
    Donate = 7,
    Contact = 8,
    Partner = 9
}