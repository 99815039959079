<div fxLayout="row" fxFlex="100%">

    <!-- (submit)="updatePage()" -->
    <form #f="ngForm" name="theForm">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <h4>Profil</h4>
                </mat-card-title>
                <mat-card-subtitle>
                    <!-- Salveaza -->
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="userProfile" fxLayout="column" fxFlex="100%">
                    <div fxLayout="row" fxFlex="100%">
                        <mat-form-field appearance="outline" style="width: 80%;">
                            <mat-label>Companie</mat-label>
                            <input matInput placeholder="Companie" [(ngModel)]='userProfile.Companie' name='Company'>
                            <mat-icon matSuffix>business</mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <mat-form-field appearance="outline" style="width: 80%;">
                            <mat-label>Numele user-ului(alias)</mat-label>
                            <input matInput placeholder="Username" [(ngModel)]='userProfile.Alias' name='Alias'>
                            <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <mat-form-field appearance="outline" style="width: 80%;">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" [(ngModel)]='userProfile.Email' name='Email'>
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Nume administrator</mat-label>
                                <input matInput placeholder="Nume" type="text"
                                    [(ngModel)]='userProfile.AdministratorNume' name='AdministratorNume'>
                                <mat-icon matSuffix>person_pin</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Prenume administrator</mat-label>
                                <input matInput placeholder="Prenume" type="text"
                                    [(ngModel)]='userProfile.AdministratorPrenume' name='AdministratorPrenume'>
                                <mat-icon matSuffix>person_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <mat-form-field appearance="outline" style="width: 80%;">
                            <mat-label>Adresa</mat-label>
                            <input matInput placeholder="Adresa" [(ngModel)]='userProfile.Adresa' name='Adresa'>
                            <mat-icon matSuffix>location_on</mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Oras</mat-label>
                                <input matInput placeholder="Oras" type="text" [(ngModel)]='userProfile.Oras'
                                    name='Oras'>
                                <mat-icon matSuffix>location_city</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Tara</mat-label>
                                <input matInput placeholder="Tara" type="text" [(ngModel)]='userProfile.Tara'
                                    name='Tara'>
                                <mat-icon matSuffix>flag</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Cod Postal</mat-label>
                                <input matInput placeholder="Cod Postal" type="text" [(ngModel)]='userProfile.CodPostal'
                                    name='CodPostal'>
                                <mat-icon matSuffix>local_post_office</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Telefon 1</mat-label>
                                <input matInput placeholder="Telefon 1" type="text" [(ngModel)]='userProfile.Telefon1'
                                    name='Telefon 1'>
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Telefon 2</mat-label>
                                <input matInput placeholder="Telefon 2" type="text" [(ngModel)]='userProfile.Telefon2'
                                    name='Telefon 2'>
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline" style="width: 80%;">
                                <mat-label>Telefon 3</mat-label>
                                <input matInput placeholder="Telefon 3" type="text" [(ngModel)]='userProfile.Telefon3'
                                    name='Telefon 3'>
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <mat-label>Program</mat-label>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" style="width: 80%;">
                            <!-- <ckeditor id="editor" [editor]="Editor1" [data]="userProfile.Program"
                                (change)="onProgramChange($event)">
                            </ckeditor> -->
                        </div>
                    </div>
                    <!-- <div>
                        <mat-label>Descriere</mat-label>
                    </div> -->
                    <!-- <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" style="width: 80%;">
                            <ckeditor [editor]="Editor2" [data]="userProfile.Descriere"
                                (change)="onDescriptionChange($event)">
                            </ckeditor>
                        </div>
                    </div> -->
                </div>
            </mat-card-content>
            <mat-card-actions align="end" fxFlex="80%">
                <div fxFlex></div>

                <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center end">

                    <button mat-button pButton type="button" [disabled]="f.invalid" style="height: 50px"
                        (click)="save()">
                        <mat-icon>save</mat-icon>
                        Salveaza
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>

</div>
<!-- 
<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="row">

                                <button mat-raised-button type="submit" class="btn btn-danger pull-right">Update
                                    Profile</button>
                                <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile">
                    <div class="card-avatar">
                        <a href="javascript:void(0)">
                            <img class="img" src="./assets/img/faces/marc.jpg" />
                        </a>
                    </div>
                    <div class="card-body">
                        <h6 class="card-category text-gray">CEO / Co-Founder</h6>
                        <h4 class="card-title">Alec Thompson</h4>
                        <p class="card-description">
                            Don't be scared of the truth because we need to restart the human foundation in truth And I
                            love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
                        </p>
                        <a href="javascript:void(0)" class="btn btn-danger btn-round">Follow</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->