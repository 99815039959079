import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeSettingsComponent } from './administration/administration.component';
import { AdminCenterComponent } from './admin-center/admin-center.component';
import { AdminAboutComponent } from './admin-about/admin-about.component';
import { AdminChristmasComponent } from './admin-christmas/admin-christmas.component';
import { AdminChildrenComponent } from './admin-children/admin-children.component';
import { AdminDonateComponent } from './admin-donate/admin-donate.component';
import { AdminContactComponent } from './admin-contact/admin-contact.component';
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminProjectsComponent } from './admin-projects/admin-projects.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AuthGuard } from '../core/shared/authGuard.service';
import { NotFoundComponent } from '../core/components/not-found/not-found.component';
import { ContentComponent } from './content/content.component';
import { AdminPartnerComponent } from './admin-partner/admin-partner.component';

const routes: Routes = [
  {
    path: '', component: ContentComponent,

  },
  { path: 'admin-about', component: AdminAboutComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]
  { path: 'admin-center', component: AdminCenterComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-children', component: AdminChildrenComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-christmas', component: AdminChristmasComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-contact', component: AdminContactComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-donate', component: AdminDonateComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-footer', component: AdminFooterComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-home', component: AdminHomeComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]
  { path: 'admin-projects', component: AdminProjectsComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-users', component: AdminUsersComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]
  { path: 'admin-partner', component: AdminPartnerComponent, canActivate: [AuthGuard] }, // , canActivate: [AuthGuard]]

  {
    path: '', redirectTo: 'admin-home', pathMatch: 'full'
  },
  { path: '**', component: NotFoundComponent }
  // {
  //   path: 'backoffice/administration',
  //   // component: HomeSettingsComponent,
  //   children: [
  //     { path: 'admin-about', component: AdminAboutComponent }, // , canActivate: [AuthGuard]
  //     { path: 'admin-center', component: AdminCenterComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-children', component: AdminChildrenComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-christmas', component: AdminChristmasComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-contact', component: AdminContactComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-donate', component: AdminDonateComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-footer', component: AdminFooterComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-home', component: AdminHomeComponent }, // , canActivate: [AuthGuard]
  //     { path: 'admin-projects', component: AdminProjectsComponent }, // , canActivate: [AuthGuard]]
  //     { path: 'admin-users', component: AdminUsersComponent }, // , canActivate: [AuthGuard]]
  //   ]
  // }, // , canActivate: [AuthGuard]
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
