<div>
  <app-user-toolbar></app-user-toolbar>
  <mat-toolbar color="" flex>
    <mat-toolbar-row class="fixed-header">

      <span><img src="./assets/logo.png" style="width:100px; height: 100px; top: 20px;" routerLink="/"></span>

      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-md>
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a mat-icon-button routerLink="">
          <mat-icon>home</mat-icon>
        </a>
        <a mat-button routerLinkActive="active" [matMenuTriggerFor]="appMenu">Despre noi</a>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item routerLink="/about/description" (click)="toggle()">Descriere</button>
          <button mat-menu-item routerLink="/about/articles" (click)="toggle()">Articole
            media</button>
        </mat-menu>
        <!-- <a mat-button routerLink="/projects">Proiecte</a> -->
        <a mat-button routerLinkActive="active" [matMenuTriggerFor]="appMenuProjects">Proiecte</a>

        <mat-menu #appMenuProjects="matMenu">
          <button mat-menu-item routerLink="/projects/final" (click)="toggle()">Proiecte
            finalizate</button>
          <button mat-menu-item routerLink="/projects/future" (click)="toggle()">Proiecte
            viitoare</button>
        </mat-menu>
        <a mat-button routerLink="/center">Centrul de recuperare</a>
        <a mat-button routerLink="/christmas">Craciunei si craciunite</a>
        <a mat-button routerLink="/donate">Cum donam?</a>
        <a mat-button routerLink="/partners">Parteneri</a>
        <a mat-button routerLink="/contact">Contact</a>
        <a mat-button routerLink="/login" *ngIf="!(isLoggedIn | async)">
          <mat-icon>account_circle</mat-icon>Login
        </a>
        <a mat-button (click)="onLogout()" *ngIf="isLoggedIn | async">
          <mat-icon>logout</mat-icon>Logout
        </a>
      </div>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav id="sidenav" fxLayout="column" mode="over" opened="false" fxHide.gt-sm="true"
      fixedInViewport="true" position="end">
      <mat-nav-list>
        <mat-list-item><a mat-button routerLink="">
            <mat-icon>home</mat-icon>
          </a></mat-list-item>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="toggle()" routerLink="/about/description">Descriere</button>
          <button mat-menu-item (click)="toggle()" routerLink="/about/articles">Articole media</button>
        </mat-menu>
        <!-- <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button> -->
        <mat-list-item><a mat-button routerLinkActive="active" [matMenuTriggerFor]="appMenu">Despre noi</a>
        </mat-list-item>
        <!-- <mat-menu #appMenu="matMenu">
          <button mat-menu-item routerLink="/projects/final" (click)="toggle()" [matMenuTriggerFor]="appMenu">Proiecte
            finalizate</button>
          <button mat-menu-item routerLink="/projects/future" (click)="toggle()" [matMenuTriggerFor]="appMenu">Proiecte
            viitoare</button>
        </mat-menu>
        <a mat-button routerLinkActive="active" [matMenuTriggerFor]="appMenu">Proiecte</a> -->
        <mat-list-item><a mat-button [matMenuTriggerFor]="appMenuProjects"
            [matMenuTriggerFor]="appMenuProjects">Proiecte</a>
        </mat-list-item>
        <mat-menu #appMenuProjects="matMenu">
          <button mat-menu-item (click)="toggle()" routerLink="/projects/final">Proiecte
            finalizate</button>
          <button mat-menu-item (click)="toggle()" routerLink="/projects/future">Proiecte
            viitoare</button>
        </mat-menu>

        <!-- <mat-list-item> <a mat-button routerLink="/projects">Proiecte</a> </mat-list-item> -->
        <mat-list-item> <a mat-button routerLink="/center">Centrul de recuperare</a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/christmas">Craciunei si craciunite</a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/donate">Cum donam?</a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/partners">Parteneri</a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/contact">Contact</a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/login" *ngIf="!(isLoggedIn | async)">
            <mat-icon>account_circle</mat-icon>Login
          </a> </mat-list-item>
        <mat-list-item> <a mat-button routerLink="/logout" (click)="onLogout()" *ngIf="isLoggedIn | async">
            <mat-icon>logout</mat-icon>Logout
          </a>
        </mat-list-item>
        <!-- <a mat-button (click)="onLogout()">Logout</a> -->

        <mat-list-item> <a (click)="sidenav.close()" mat-list-item>Close</a> </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill style="height: 200vh;">
      <!-- Menu -->
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->
</div>