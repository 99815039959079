<form #f="ngForm" name="theForm">
  <app-command-bar>
    <h1>Despre noi</h1>
  </app-command-bar>
  <app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="updatePage()">
      <mat-icon>save</mat-icon>
      {{ 'Save' }}
    </button>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
      <mat-card-subtitle>
        Centrul de recuperare
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="centerPage && (centerPage?.Text1 || contentLoaded)">
        <ckeditor [editor]="editorContent" [data]="centerPage.Text1" (change)="onContentChange($event)"></ckeditor>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <app-file-upload [pageId]="pagesEnum.Center" [files]="images"></app-file-upload>
  </mat-card>
</form>
<br>