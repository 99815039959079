<form #f="ngForm" name="theForm">
  <app-command-bar>
    <h1>Despre noi</h1>
  </app-command-bar>
  <app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="updatePage($event)">
      <mat-icon>save</mat-icon>
      {{ 'Save' }}
    </button>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
      <mat-card-subtitle>
        Adauga motto
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="homePage && (homePage?.Motto || contentLoaded)">
        <ckeditor [editor]="editorContent" [data]="homePage.Motto" (change)="onContentChange($event)"></ckeditor>
      </div>
    </mat-card-content>

  </mat-card>

  <mat-card>
    <app-file-upload [pageId]="pagesEnum.Home" [files]="images"></app-file-upload>
  </mat-card>
</form>
<br>