import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { AlertService } from 'src/app/core/services/alert.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-donate',
  templateUrl: './admin-donate.component.html',
  styleUrls: ['./admin-donate.component.scss']
})
export class AdminDonateComponent implements OnInit {

  public editorContent = ClassicEditor;
  public editorText = ClassicEditor;
  public editorTextLoaded = false;
  public donatePage: PageModel;

  constructor(private pageService: PageService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.getData();
  }
  getData() {
    this.pageService.findOne(Pages.Donate).subscribe(
      res => {
        this.donatePage = res;
        this.editorTextLoaded = true;
      });
  }
  public onTextChange({ editor }: ChangeEvent) {
    if (editor) {
      this.donatePage.Text1 = editor.getData();
      if (!this.donatePage.Text1 || this.donatePage.Text1.length === 0) {
        this.donatePage.Text1 = " ";
      }
    }
  }

  updatePage(event) {
    this.pageService.update(Pages.Donate, this.donatePage)
      .subscribe((res) => {
        this.donatePage = res;
        this.alertService.success('Updated successfully');

      },
        (err) => this.alertService.error(err));
  }
}
