export class ColumnConfig {
    name?: string;
    displayName?: string;
    isSort?: boolean;
    type: filedType;
    options?: FilterOptions[];
    sticky?: string;
    sortActive?: sortDirections;
    component?: any;
}
export class TableConfig {
    showCheckAll = true;
    showSearchInput = true;
    showFilterInputOnHeader = false;
    showFilter = false;
}
export enum sortDirections {
    asc = 'asc',
    desc = 'desc'
}

export enum filedType {
    string = 'string',
    enum = 'enum',
    component = 'component',
    phone = 'phone',
    address = 'address',
    email = 'email',
    file = 'file'
}
export interface FilterOptions {
    name: string;
    displayName: string;
    selected: boolean;
}