<form #form="ngForm" name="theFirstForm">
  <app-command-bar>
    <h1>Craciunei si craciunite</h1>
  </app-command-bar>
  <app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="updatePage($event)">
      <mat-icon>save</mat-icon>
      {{ 'Save' }}
    </button>
  </app-command-bar>
  <mat-card>
    <mat-card-header>

    </mat-card-header>
    <mat-card-content>

      <div *ngIf="christmasPage.Content || editorContentLoaded">
        <ckeditor [editor]="editorContent" [data]="christmasPage.Content" (change)="onContentChange($event)"></ckeditor>
      </div>
      <div *ngIf="christmasPage.Text1 || editorTextLoaded">
        <ckeditor [editor]="editorText" [data]="christmasPage.Text1" (change)="onTextChange($event)"></ckeditor>
      </div>

    </mat-card-content>
  </mat-card>
</form>