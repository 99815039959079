<mat-toolbar class="app-command-bar" fxHide.xs>
  <mat-toolbar-row class="app-command-bar-row">
    <ng-content> </ng-content>
  </mat-toolbar-row>
</mat-toolbar>

<!--

<mat-toolbar class="crm-command-bar-height">

  <mat-toolbar-row class="crm-command-bar-height">

    <ng-content> </ng-content>

  </mat-toolbar-row>

</mat-toolbar>


-->

<!--

<mat-toolbar class="command-bar-height">

  <mat-toolbar-row class="command-bar-height">

    <button mat-button class="command-bar-button">
      <mat-icon>add</mat-icon>
      NEW
    </button>

    <button mat-button class="command-bar-button" [disabled]="true">
      <mat-icon>visibility</mat-icon>
      ACTIVATE
    </button>

    <button mat-button class="command-bar-button">
      <mat-icon>visibility_off</mat-icon>
      DEACTIVATE
    </button>

    <button mat-button class="command-bar-button">
      <mat-icon>link</mat-icon>
      EMAIL A LINK
    </button>

    <button mat-button class="command-bar-button">
      <mat-icon>description</mat-icon>
      RUN REPORT
    </button>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">

      <button mat-button class="command-bar-button">
        <mat-icon>open_in_new</mat-icon>
      </button>

    </div>

  </mat-toolbar-row>

</mat-toolbar>



      <button mat-button class="command-bar-button">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <button mat-button class="command-bar-button">
        <mat-icon>delete</mat-icon>
        DELETE
      </button>

    <div class="command-bar-container">

-->