import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommandBarComponent } from '../components/command-bar/command-bar.component';
import { BaseSectionBarComponent } from '../components/base-section-bar/base-section-bar.component';
import { DatatableComponent } from '../components/datatable/datatable.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AppMaterialModule } from '../material/app.material';
import { LoadingComponent } from '../components/loading/loading.component';
import { UserToolbarComponent } from '../header/user-toolbar/user-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    AppMaterialModule,
    CommonModule
  ],
  declarations: [
    CommandBarComponent,
    BaseSectionBarComponent,
    DatatableComponent,
    UserToolbarComponent,
    LoadingComponent
  ],
  exports: [
    CommandBarComponent,
    BaseSectionBarComponent,
    DatatableComponent,
    CKEditorModule,
    UserToolbarComponent,
    LoadingComponent,
    CommonModule
  ],
})
export class SharedModule { }
