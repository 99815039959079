import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from './SpinnerService';

@Injectable()
export class CommonService {
    constructor(private spinnerService: SpinnerService) {

    }
    public handleError(error: HttpErrorResponse) {
        if (this.spinnerService) {
        }
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(
            //     `Backend returned code ${error.status}, ` +
            //     `body was: ${error.error}`);
        }

        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}


