import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnConfig, filedType, TableConfig } from 'src/app/core/components/datatable/utils/column-config';
import { MediaArticleModel } from 'src/app/core/entities/models/media-article.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { MediaArticleService } from 'src/app/core/services/media-article.service';
import { AdminArticleDialogComponent } from '../admin-article/admin-article-dialog/admin-article-dialog.component';

@Component({
  selector: 'app-admin-media',
  templateUrl: './admin-media.component.html',
  styleUrls: ['./admin-media.component.scss']
})
export class AdminMediaComponent implements OnInit {

  @Input() public selection = new SelectionModel<MediaArticleModel>(true, []);

  public editDialog = AdminArticleDialogComponent;
  public mediaArticles: MediaArticleModel[] = [];

  public dataSource = new MatTableDataSource<MediaArticleModel>(this.mediaArticles);
  public partnerPageMessage: string = '';
  public columns: ColumnConfig[] = [
    {
      name: 'Name',
      displayName: 'Nume',
      type: filedType.string,
    },
    {
      name: 'Description',
      displayName: 'Description',
      type: filedType.string,
    },
    {
      name: 'URL',
      displayName: 'URL',
      type: filedType.string,
    },
  ];

  public tableConfig: TableConfig = {
    showCheckAll: false,
    showSearchInput: false,
    showFilterInputOnHeader: false,
    showFilter: false,
  };
  constructor(public mediaArticleService: MediaArticleService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public alertService: AlertService,
  ) { }

  public ngOnInit(): void {
    this.loadData();
  }

  public createNew(): void {
    this.openDialog(new MediaArticleModel());
  }

  public loadData(): void {
    this.mediaArticleService.findAll()
      .subscribe((mediaArticles) => {
        this.mediaArticles = mediaArticles;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource<MediaArticleModel>(this.mediaArticles);
      });
  }


  public openDialog(obj: MediaArticleModel): void {
    if (this.editDialog) {
      const dialogRef = this.dialog.open(this.editDialog, {
        width: '750px',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '40vh',
        data: {
          model: obj,
          action: 'Save',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result || result.event === 'Cancel') {
          return;
        }
        if (result.data) {
          this.addRowData(result.data);
        }
      });
    }
  }

  public addRowData(row: MediaArticleModel): void {
    this.mediaArticleService.create(row)
      .subscribe(
        () => {
          this.alertService.success(this.translateService.instant('GENERIC.SUCCES_CONFIRMATION'));

          this.loadData();
        },
        (error) => {
          this.alertService.error(error);
        },
      );
  }


  public rowRightClicked(event): void {
    console.log(event);
  }

  public selected(selected): void {
    selected.map((row) => {
      this.selection.toggle(row);
    });
  }

  public onFilter(event): void {

  }

  public onSort(event): void {
    console.log(event);
  }

  public rowClicked(event): void {
    console.log(event);
  }

  public onColumnFiltered(event): void {
    console.log(this.columns);
    console.log(event);
  }
}
