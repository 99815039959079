import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnConfig, filedType, sortDirections, TableConfig } from 'src/app/core/components/datatable/utils/column-config';
import { PartnerModel } from 'src/app/core/entities/models/partner.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { PartnerService } from 'src/app/core/services/partner.service';
import { AdminPartnerDialogComponent } from './admin-partner-dialog/admin-partner-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { PageModel } from 'src/app/core/entities/models/page.model';

import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-partner',
  templateUrl: './admin-partner.component.html',
  styleUrls: ['./admin-partner.component.scss']
})
export class AdminPartnerComponent implements OnInit {
  @Input() public selection = new SelectionModel<PartnerModel>(true, []);

  public editDialog = AdminPartnerDialogComponent;
  public partnerPage: PageModel = new PageModel();
  public partners: PartnerModel[] = [];
  public editor = ClassicEditor;

  public dataSource = new MatTableDataSource<PartnerModel>(this.partners);
  public partnerPageMessage: string = '';
  public columns: ColumnConfig[] = [

    {
      name: 'Name',
      displayName: 'Companie',
      type: filedType.string,
    },

    {
      name: 'Phone',
      displayName: 'Telefon',
      type: filedType.string,
    },
    {
      name: 'Website',
      displayName: 'Website',
      type: filedType.string,
    },

  ];

  public tableConfig: TableConfig = {
    showCheckAll: false,
    showSearchInput: false,
    showFilterInputOnHeader: false,
    showFilter: false,
  };
  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    public partnerService: PartnerService,
    public alertService: AlertService,
    public pageService: PageService,

  ) { }

  public ngOnInit(): void {
    this.loadData();
  }

  public createNew(): void {
    this.openDialog(new PartnerModel());
  }

  public loadData(): void {
    this.pageService.findOne(Pages.Partner)
      .subscribe((page) => {
        this.partnerPage = page;
        this.partnerPageMessage = page.Text1;
      });

    this.partnerService.findAll()
      .subscribe((partners) => {
        this.partners = partners;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource<PartnerModel>(this.partners);
      });
  }


  public openDialog(obj: PartnerModel): void {
    if (this.editDialog) {
      const dialogRef = this.dialog.open(this.editDialog, {
        width: '750px',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '40vh',
        data: {
          model: obj,
          action: 'Save',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result || result.event === 'Cancel') {
          return;
        }
        if (result.data) {
          this.addRowData(result.data);
        }
      });
    }
  }

  public addRowData(row: PartnerModel): void {
    this.partnerService.create(row)
      .subscribe(
        () => {
          this.alertService.success(this.translateService.instant('GENERIC.SUCCES_CONFIRMATION'));

          this.loadData();
        },
        (error) => {
          this.alertService.error(error);
        },
      );
  }


  public rowRightClicked(event): void {
    console.log(event);
  }

  public selected(selected): void {
    selected.map((row) => {
      this.selection.toggle(row);
    });
  }

  public onFilter(event): void {

  }

  public onSort(event): void {
    console.log(event);
  }

  public rowClicked(event): void {
    console.log(event);
  }

  public onColumnFiltered(event): void {
    console.log(this.columns);
    console.log(event);
  }

  public onContentChange({ editor }: ChangeEvent) {
    this.partnerPage.Text1 = editor.getData();
    if (!this.partnerPage.Text1 || this.partnerPage.Text1.length === 0) {
      this.partnerPage.Text1 = " ";
    }
  }
  public savePage(): void {
    this.pageService.update(Pages.Partner, this.partnerPage).subscribe();
  }
}
