import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/frontoffice/services/SpinnerService';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
@ViewChild('spinnerElement')
export class LoadingComponent implements OnInit {
  private spinnerElement: ElementRef;
  private subscription: Subscription;
  public isLoading: Subject<boolean> = this.spinnerService.isLoading;

  constructor(private spinnerService: SpinnerService, private changeDetector: ChangeDetectorRef) {
    // this.loading = true;
  }

  ngOnInit() {
    // this.loading = true;
    // this.spinnerService.triggerEventFn(true);
    // this.subscription = this.spinnerService.spinnerActive.subscribe((val: boolean) => {
    //   this.loading = val;
    // });

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    // this.loading = false;
    // this.changeDetector.detectChanges();
    // this.subscription = this.spinnerService.spinnerActive.subscribe((val: boolean) => {
    //   this.loading = val;
    // });
  }
}
