import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { PartnerModel } from '../entities/models/partner.model';

@Injectable()
export class PartnerService extends DataService<PartnerModel, string> {
    header: any;

    public headers: HttpHeaders = new HttpHeaders();

    constructor(protected _http: HttpClient) {
        super(_http, `${environment.api.baseUrl}/partner`);
        const headerSettings: { [name: string]: string | string[]; } = {};
        this.header = new HttpHeaders(headerSettings);
    }
}
