<app-base-section-bar>
  <h1 mat-dialog-title>
    {{title}}
  </h1>
</app-base-section-bar>

<div fxLayoutAlign="center center" mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions fxLayoutAlign="end end">
  <button mat-raised-button color="warn" (click)="onConfirm()">{{'GENERIC.YES' }}</button>
  <button mat-raised-button color="primary" [mat-dialog-close] cdkFocusInitial (click)="onDismiss()">{{'GENERIC.NO'
    }}</button>
</div>