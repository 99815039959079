import { Component, OnInit } from '@angular/core';
import { PageService } from '../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { TeamsService } from 'src/app/core/services/teams.service';
import { PageModel } from 'src/app/core/entities/models/page.model';

@Component({
  selector: 'app-christmas',
  templateUrl: './christmas.component.html',
  styleUrls: ['./christmas.component.scss']
})
export class ChristmasComponent implements OnInit {
  public christmasPage: PageModel;

  constructor(private pageService: PageService
    , private teamService: TeamsService
  ) { }

  ngOnInit() {
    // this.getTeam();
    this.getData();
  }
  getTeam() {
    this.teamService.findAll().subscribe(
      () => {
      });
  }
  getData() {
    this.pageService.findOne(Pages.Christmas).subscribe(
      (res: PageModel) => {
        this.christmasPage = res;

      });
  }
}
