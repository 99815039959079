import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './frontoffice/about/about.component';
import { ContactComponent } from './frontoffice/contact/contact.component';
import { ProjectsComponent } from './frontoffice/projects/projects.component';
import { CenterComponent } from './frontoffice/center/center.component';
import { ChristmasComponent } from './frontoffice/christmas/christmas.component';
import { DonateComponent } from './frontoffice/donate/donate.component';
import { LoginComponent } from './frontoffice/login/login.component';
import { HomeComponent } from './frontoffice/home/home.component';
import { RegisterComponent } from './frontoffice/register/register.component';
import { AuthGuard } from './core/shared/authGuard.service';

import { DescriptionComponent } from './frontoffice/about/description/description.component';
import { ArticlesComponent } from './frontoffice/about/articles/articles.component';
import { HomeSettingsComponent } from './backoffice/administration/administration.component';
import { AuthenticationService } from './frontoffice/services/AuthenticateService';
import { AdminAboutComponent } from './backoffice/admin-about/admin-about.component';
import { AdminCenterComponent } from './backoffice/admin-center/admin-center.component';
import { AdminChildrenComponent } from './backoffice/admin-children/admin-children.component';
import { AdminChristmasComponent } from './backoffice/admin-christmas/admin-christmas.component';
import { AdminContactComponent } from './backoffice/admin-contact/admin-contact.component';
import { AdminDonateComponent } from './backoffice/admin-donate/admin-donate.component';
import { AdminFooterComponent } from './backoffice/admin-footer/admin-footer.component';
import { AdminHomeComponent } from './backoffice/admin-home/admin-home.component';
import { AdminProjectsComponent } from './backoffice/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './backoffice/admin-users/admin-users.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AdminProfileComponent } from './backoffice/admin-profile/admin-profile.component';
import { PartnersComponent } from './frontoffice/partners/partners.component';


const routes: Routes = [
  // { path: 'home', component: HomeCom },
  // { path: '**', redirectTo: 'home' },
  //{ path: '', component: HomeComponent }, // , canActivate: [AuthGuard]

  { path: 'home', component: HomeComponent }, // , canActivate: [AuthGuard]
  {
    path: 'about', component: AboutComponent,
    children: [
      { path: 'description', component: DescriptionComponent }, // , canActivate: [AuthGuard]
      { path: 'articles', component: ArticlesComponent }, // , canActivate: [AuthGuard]
    ]
  },
  {
    path: 'projects', component: ProjectsComponent,
    children: [
      { path: 'final', component: ProjectsComponent }, // , canActivate: [AuthGuard]
      { path: 'future', component: ProjectsComponent }, // , canActivate: [AuthGuard]
    ]
  },
  { path: 'center', component: CenterComponent }, // , canActivate: [AuthGuard]
  { path: 'christmas', component: ChristmasComponent }, // , canActivate: [AuthGuard]
  { path: 'donate', component: DonateComponent }, // , canActivate: [AuthGuard]
  { path: 'contact', component: ContactComponent }, // , canActivate: [AuthGuard]
  { path: 'login', component: LoginComponent }, // , canActivate: [AuthGuard]
  { path: 'register', component: RegisterComponent }, // , canActivate: [AuthGuard]
  { path: 'partners', component: PartnersComponent }, // , canActivate: [AuthGuard]
  {
    path: 'administration', component: HomeSettingsComponent, canActivate: [AuthGuard],
    loadChildren: './backoffice/backoffice.module#BackofficeModule',
  }, // , canActivate: [AuthGuard]
  { path: 'profile', component: AdminProfileComponent }, // , canActivate: [AuthGuard]
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
