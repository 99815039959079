import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PartnerModel } from '../../entities/models/partner.model';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent implements OnInit {
  @Input() public data: PartnerModel;

  constructor() { }

  ngOnInit(): void {
  }

}
