<form #form="ngForm" class="description-card container-wrapper">
  <app-command-bar>
    <h1>Craciunei si Craciunite</h1>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="christmasPage?.Text1" class="text-justify" [innerHTML]="christmasPage?.Text1 | keepHtml"></div>
    </mat-card-content>
  </mat-card>
</form>