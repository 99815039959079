import { SpinnerService } from '../SpinnerService';
import { HttpRequest, HttpInterceptor, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingOverlayRef, LoadingService } from '../loading.service';

@Injectable()
export class SpinnerInterceptor<T> implements HttpInterceptor {
    private requests: HttpRequest<T>[] = [];

    constructor(private spinnerService: SpinnerService, private loadingService: LoadingService) { }

    removeRequest(req: HttpRequest<T>) {
        // const i = this.requests.indexOf(req);
        // if (i >= 0) {
        //     this.requests.splice(i, 1);
        // }

        // this.spinnerService.isLoading.next(false);
    }

    intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        let loadingRef: LoadingOverlayRef;
        this.requests.push(req);
        this.spinnerService.isLoading.next(true);
        Promise.resolve(null).then(() => loadingRef = this.loadingService.open());
        // We create a new observable which we return instead of the original
        return Observable.create(observer => {
            // And subscribe to the original observable to ensure the HttpRequest is made
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse && loadingRef) {
                            this.removeRequest(req);
                            observer.next(event);
                            this.spinnerService.isLoading.next(false);
                            loadingRef.close();
                        }
                    },
                    err => {
                        this.removeRequest(req); observer.error(err);
                        this.spinnerService.isLoading.next(false);
                        if (loadingRef) {
                            loadingRef.close();
                        }
                    },
                    () => {
                        this.removeRequest(req); observer.complete();
                        this.spinnerService.isLoading.next(false);
                        if (loadingRef) {
                            loadingRef.close();
                        }
                    });
            // return teardown logic in case of cancelled requests
            return () => {
                this.removeRequest(req);
                this.spinnerService.isLoading.next(false);

                subscription.unsubscribe();
            };
        });
    }
}