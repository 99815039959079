import { Component, Input, OnInit } from '@angular/core';
import { TeamModel } from '../../entities/models/team.model';

@Component({
  selector: 'app-teams-card',
  templateUrl: './teams-card.component.html',
  styleUrls: ['./teams-card.component.scss']
})
export class TeamsCardComponent implements OnInit {
  @Input() public data: TeamModel;

  constructor() { }

  ngOnInit(): void {
  }

}
