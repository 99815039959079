<form #f="ngForm" name="theForm">
  <!-- (selectedTabChange)="currTab($event)" -->
  <mat-tab-group id="main">
    <mat-tab label="Despre noi">
      <app-command-bar>
        <h1>Despre noi</h1>
      </app-command-bar>
      <app-command-bar>
        <button mat-button class="crm-command-bar-button" (click)="updatePage($event)">
          <mat-icon>save</mat-icon>
          {{ 'Save' }}
        </button>
      </app-command-bar>
      <mat-card>
        <mat-card-header>

          <mat-card-title>
            <!-- <button pButton type="button" [disabled]="f.invalid" (click)="updatePage($event, page)" style="height: 30px">
                <mat-icon>save</mat-icon>
              </button> -->

          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="aboutPage && (aboutPage?.Content || aboutContentLoaded)">
            <ckeditor [editor]="editorContent" [data]="aboutPage.Content" (change)="onContentChange($event)"></ckeditor>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab label="Articole video">
      <app-command-bar>
        <h1>Articole video</h1>
      </app-command-bar>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-admin-article></app-admin-article>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab label="Articole media">
      <app-command-bar>
        <h1>Articole media</h1>
      </app-command-bar>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-admin-media></app-admin-media>

          <!-- <div *ngIf="aboutPage && (aboutPage?.Text1 || aboutTextLoaded)">
            <ckeditor [editor]="editorText" [data]="aboutPage.Text1" (change)="onTextChange($event)"></ckeditor>
          </div> -->
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Echipa noastra">
      <mat-card>
        <mat-card-header>

          <mat-card-title>


          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-admin-team></app-admin-team>
        </mat-card-content>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <br />


  <br />

</form>