import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './core/header/header.component';
import { ContentComponent } from './core/content/content.component';
import { FooterComponent } from './core/footer/footer.component';
import { AppMaterialModule } from './core/material/app.material';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImagesService } from './frontoffice/services/ImagesService';
import { RegisterComponent } from './frontoffice/register/register.component';
import { AuthenticationService } from './frontoffice/services/AuthenticateService';
import { SpinnerService } from './frontoffice/services/SpinnerService';
import { CommonService } from './frontoffice/services/CommonService';
import { UserService } from './frontoffice/services/UserService';
import { ErrorInterceptor } from './core/shared/interceptor.service';
import { ValidatorService } from './core/shared/validator.service';
import { AuthGuard } from './core/shared/authGuard.service';
import { EscapeHtmlPipe } from './core/pipes/EscapeHtmlPipe';
import { ArticlesComponent } from './frontoffice/about/articles/articles.component';
import { DescriptionComponent } from './frontoffice/about/description/description.component';
import { SideNavService } from './frontoffice/services/SideNavService';
import { HomeComponent } from './frontoffice/home/home.component';
import { AboutComponent } from './frontoffice/about/about.component';
import { ContactComponent } from './frontoffice/contact/contact.component';
import { ProjectsComponent } from './frontoffice/projects/projects.component';
import { CenterComponent } from './frontoffice/center/center.component';
import { ChristmasComponent } from './frontoffice/christmas/christmas.component';
import { DonateComponent } from './frontoffice/donate/donate.component';
import { LoginComponent } from './frontoffice/login/login.component';
import { HomeSettingsComponent } from './backoffice/administration/administration.component';
import { BackofficeModule } from './backoffice/backoffice.module';
import { SpinnerInterceptor } from './frontoffice/services/Interceptors/spinner.interceptor';
import { PageService } from './frontoffice/services/PagesServices';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { PartnersComponent } from './frontoffice/partners/partners.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PartnerService } from './core/services/partner.service';
import { AlertService } from './core/services/alert.service';
import { SharedModule } from './core/modules/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BusinessCardComponent } from './core/components/business-card/business-card.component';
import { TeamsService } from './core/services/teams.service';
import { TeamsCardComponent } from './core/components/teams-card/teams-card.component';
import { TokenInterceptor } from './core/helpers/token.interceptor';
import { VideoArticleService } from './core/services/video-article.service';
import { SafeUrlResource } from './core/pipes/SafeUrlResource.pipe';
import { MediaArticleService } from './core/services/media-article.service';
import { UploadService } from './core/services/upload.service';
import { LoadingService } from './frontoffice/services/loading.service';
import { GdprComponent } from './frontoffice/gdpr/gdpr.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
@NgModule({

  declarations: [
    EscapeHtmlPipe,
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    ProjectsComponent,
    CenterComponent,
    ChristmasComponent,
    DonateComponent,
    LoginComponent,
    HomeComponent,
    HomeSettingsComponent,
    RegisterComponent,
    ArticlesComponent,
    DescriptionComponent,
    PartnersComponent,
    BusinessCardComponent,
    TeamsCardComponent,
    SafeUrlResource,
    GdprComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    FormsModule,
    ToastrModule.forRoot(), // ToastrModule added,
    NgxImageGalleryModule,
    BackofficeModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    // JwtModule.forRoot(JWT_Module_Options)
    GalleryModule,
    LightboxModule
  ],
  exports: [
    AppMaterialModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    VideoArticleService,
    MediaArticleService,
    SpinnerService,
    CommonService,
    UserService,
    AuthenticationService,
    ImagesService,
    PageService,
    TeamsService,
    AuthGuard,
    ValidatorService,
    PartnerService,
    AlertService,
    SideNavService,
    UploadService,
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
