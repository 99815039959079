<form #form="ngForm" class="description-card container-wrapper">
    <app-command-bar>
        <h1>Parteneri in fapte bune</h1>
    </app-command-bar>
    <mat-card>
        <mat-card-header>

        </mat-card-header>
        <mat-card-content>
            <div *ngIf="partnerPage && partnerPage.Text1">
                <div class="text-justify" [innerHTML]="partnerPage.Text1 | keepHtml"></div>
            </div>

            <div *ngFor="let partner of partners">
                <app-business-card [data]="partner"></app-business-card>

            </div>

        </mat-card-content>
    </mat-card>
</form>