<!-- {{diagnostic}} -->

<div class="loginPage">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Autentificare</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="loginForm" #loginForm="ngForm">

        <div class="bounds full-width" style="max-width: 70%; margin-left: 15%; margin-right: 15%">

          <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
            <mat-form-field style="width: 100%;">
              <mat-label>Utilizator</mat-label>
              <input matInput type="email" placeholder="Utilizator" [(ngModel)]="username" name="username" required
                minlength="4">
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
          </div>
          <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
            <mat-form-field style="width: 100%;">
              <mat-label>Parola</mat-label>
              <input matInput type="password" placeholder="Parola" [(ngModel)]="password" name="password" required
                minlength="3">
              <mat-icon matSuffix>lock_open</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div style="max-width: 70%; margin-left: 15%; margin-right: 15%">
        <button mat-raised-button [disabled]="!loginForm.form.valid" (click)="login()"
          color="primary">Autentificare</button>
        <!-- <button mat-raised-button routerLink="/forgot" color="primary">Ai uitat parola?</button>
        <button mat-raised-button routerLink="/register" color="primary">Inregistrare</button> -->
      </div>

    </mat-card-actions>
  </mat-card>
</div>

<!-- <input type="email"
       ng-model="string"
       [name="string"]
       [required="string"]
       [ng-required="string"]
       [ng-minlength="number"]
       [ng-maxlength="number"]
       [pattern="string"]
       [ng-pattern="string"]
       [ng-change="string"]> -->