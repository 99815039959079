import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

import { PageModel } from '../../../core/entities/models/page.model';
import { SpinnerService } from '../../services/SpinnerService';
import { UserService } from '../../services/UserService';
import { PageService } from '../../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { TeamsService } from 'src/app/core/services/teams.service';
import { TeamModel } from 'src/app/core/entities/models/team.model';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  public aboutPage: PageModel;
  public teams: TeamModel[] = [];


  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
    , private teamService: TeamsService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    // about page
    this.pageService.findOne(Pages.About).subscribe(
      res => {
        this.aboutPage = res;
      });
    this.teamService.findAll().subscribe(
      teams => {
        this.teams = teams;
      });
  }

}
