import { SelectionModel } from "@angular/cdk/collections";
import * as _moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";

import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ColumnConfig, TableConfig } from "./utils/column-config";

import { ConfirmDialogModel } from "../../entities/models/confirm-dialog.model";
@Component({
	selector: "app-datatable",
	templateUrl: "./datatable.component.html",
	styleUrls: ["./datatable.component.scss"]
})
export class DatatableComponent implements OnInit, AfterViewInit {
	@ViewChild(MatTable, { static: true }) table: MatTable<any>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@Input() public data: any;
	@Input() public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	@Input() public displayedColumns: ColumnConfig[] = [];
	@Input() public tableConfig: TableConfig = new TableConfig();

	@Input() public multiSelect = false;
	@Input() public filterInit = {};
	@Input() public sortInit = {};
	@Input() public selectedRowInit = {};
	@Input() public editDialog: any;
	@Input() public service: any;
	@Input() public filterComponent: any;
	@Input() public isLoading = true;

	@Output() sort: EventEmitter<any> = new EventEmitter<any>();
	@Output() loadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() filter: EventEmitter<string> = new EventEmitter<string>();
	@Output() selected: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() columnFiltered: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowRightClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectedRows: EventEmitter<Selection> = new EventEmitter<Selection>();
	@Output() downloadFile: EventEmitter<any> = new EventEmitter<any>();

	private alertOptions = {
		autoClose: true,
		keepAfterRouteChange: false
	};

	public activeSort: ColumnConfig = null;
	public selectedRowIndex = -1;
	public selection: SelectionModel<any>;
	public col = [];

	public pageSize = 10;
	public currentPage = 0;
	public totalSize = 0;
	public pageEvent: PageEvent;

	constructor(
		private sanitizer: DomSanitizer,
		public dialog: MatDialog,
		public translateService: TranslateService,
	) {
		//this.dataSource.paginator = this.paginator;

	}

	public ngOnInit(): void {
		const s = this.filterComponent;
		this.dataSource.paginator = this.paginator;
		this.totalSize = this.data.length;
		this.col = this.displayedColumns.map((x: ColumnConfig) => x.name);
		if (true) {
			if (this.tableConfig.showCheckAll) {
				this.col.unshift("select");
			}
			this.col.push("actions");
		}

		const initialSelection = [];
		const allowMultiSelect = true;
		this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
		this.activeSort = this.displayedColumns.find((x) => x.sortActive !== null);
		this.handlePage();

	}

	public ngAfterViewInit(): void {
		this.dataSource.paginator = this.paginator;
	}


	public sortData(event): void {
		this.sort.emit(event);
	}

	public onLoadMore(): void {
		this.loadMore.emit(true);
	}

	public onFilter(text): void {
		this.filter.emit(text);
	}
	public applyFilter(filterValue: string): void {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}

	public onSelectRow(event, row): void {
		if (event) {
			this.selection.toggle(row);
		}
		this.selected.emit(this.selection.selected);
	}

	public onSelectMaster(event): void {
		if (event) {
			this.masterToggle();
		}
		this.selected.emit(this.selection.selected);
	}

	public isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	public masterToggle(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));
	}

	public rowClick(row): void {
		this.selectedRowIndex = row.id;
		this.rowClicked.emit(row);
		this.openDialog("Update", row);
	}

	public onColumnFilter(column, option, event): void {
		const colIndex = this.displayedColumns.indexOf(column);
		if (colIndex > -1) {
			const optionIndex = this.displayedColumns[colIndex].options.indexOf(option);
			this.displayedColumns[colIndex].options[optionIndex].selected = event.checked;
		}

		this.columnFiltered.emit({ column: column.Name, option: option.Name, checked: event.checked });
	}

	public sanitize(element): any {
		return this.sanitizer.bypassSecurityTrustHtml(element);
	}

	public rightClick(row): void {
		this.rowRightClicked.emit(row);
	}

	public handlePage(e?: any): void {
		this.currentPage = e?.pageIndex || 0;
		this.pageSize = e?.pageSize || 10;
		this.iterator();
		this.dataSource.paginator = this.paginator;

	}

	private iterator(): void {
		this.totalSize = this.data.length;
		const end = (this.currentPage + 1) * this.pageSize;
		const start = this.currentPage * this.pageSize;
		const part = this.data.slice(start, end);
		this.dataSource.data = part;
		this.isLoading = false;


		this.dataSource.filterPredicate = (data: any, filter: string) => {
			let dataStr = "";
			this.displayedColumns.forEach((column) => {
				if (data["" + column.name + ""]) {
					dataStr += " " + data["" + column.name + ""].toString().trim().toLowerCase()
				}
			});
			return dataStr.indexOf(filter) != -1;
		};
	}

	public logSelection(): void {
		this.selection.selected.forEach(s => console.log(s.name));
	}

	public onTabChanged(): void {

	}

	public openDialog(action, obj): void {
		if (this.editDialog) {
			obj.action = action;
			const dialogRef = this.dialog.open(this.editDialog, {
				width: "750px",
				maxWidth: "100vw",
				maxHeight: "100vh",
				height: "auto",
				data: {
					model: obj,
					action
				}
			});

			dialogRef.afterClosed().subscribe(result => {
				if (!result || result.event === "Cancel") {
					return;
				}
				if (result.event === "Add") {
					this.addRowData(result.data);
				} else if (result.event === "Update") {
					this.updateRowData(result.data);
				} else if (result.event === "Delete") {
					this.deleteRowData(result.data);
				}
				this.handlePage();
			});
		}
	}

	public confirmDialog(row): void {
		const message = this.translateService.instant("MESSAGES.CONFIRMATION_MESSAGE");
		const dialogData = new ConfirmDialogModel(this.translateService.instant("GENERIC.CONFIRMATION"), message);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: "500px",
			maxWidth: "600px",

			height: "auto",
			data: dialogData
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result || result.event === "Cancel") {
				return;
			} else {
				this.deleteRowData(row);
			}
		});
	}

	public addRowData(row): void {
		const d = new Date();
		this.dataSource.data.push({
			id: d.getTime(),
			name: row.Name
		});
		this.table.renderRows();

	}

	public updateRowData(row): void {
		this.dataSource.data = this.dataSource.data.filter((value) => {
			if (value.Id === row.Id) {
				this.service.update(row.Id, row).subscribe(
					() => {
						// this.alertService.success(this.translateService.instant("GENERIC.SUCCES_CONFIRMATION"), this.alertOptions);
					},
					error => {
						// this.alertService.error(error, this.alertOptions);
					});
			}
			return true;
		});
		this.table.renderRows();

	}

	public deleteRowData(row: any): void {
		this.dataSource.data = this.dataSource.data.filter((value) => {
			if (value.Id === row.Id) {
				this.service.delete(row.Id).subscribe(
					() => {
						// this.alertService.success(this.translateService.instant("GENERIC.SUCCES_CONFIRMATION"), this.alertOptions);

					},
					error => {
						// this.alertService.error(error, this.alertOptions);
					});
			}
			return value.Id !== row.Id;
		});
	}

	public download(row: any): void {
		this.downloadFile.emit(row);

		// this.service.download(row.Id).subscribe(data => this.downLoadFile(row, data, "octet/stream; charset=utf-8"));
	}

	// this._reportService.getReport().subscribe(data => this.downloadFile(data)),//console.log(data),
	// error => console.log('Error downloading the file.'),
	// () => console.info('OK');

	// public downLoadFile(row, data: any, type: string): void {

	// 	var blob = new Blob([data], { type: type.toString() });
	// 	const extension = row.ArticolFile.split(".").pop();
	// 	fileSaver.saveAs(blob, row.Name + "_" + row.Surname + "_Articol" + "." + extension);//+ "file_name.doc"
	// }


	public getColumnLabel(column: ColumnConfig): string {

		return column.displayName;
	}
}
