<mat-toolbar color="" flex *ngIf="isLoggedIn | async">
  <mat-toolbar-row class="user-fixed-header">
    <span class="menu-spacer"></span>
    <mat-menu #appProfile="matMenu">
      <!-- *ngIf="isAdmin=== 1" -->
      <button mat-menu-item (click)="navigate('administration')">
        <!-- notifications_active -->
        <mat-icon>settings</mat-icon>Administrare
      </button>
      <!-- *ngIf="isAdmin === 1" -->
      <button mat-menu-item routerLink="/notifications">
        <!-- notifications_active -->
        <mat-icon>notifications</mat-icon>Notificari
      </button>
      <button mat-menu-item (click)="navigate('profile')">
        <mat-icon>person</mat-icon> Profil
      </button>
      <button mat-menu-item (click)="onLogout()" *ngIf="isLoggedIn | async">
        <mat-icon>logout</mat-icon> Deconectare
      </button>
      <!-- <button mat-menu-item routerLink="/profile" (click)="toggle()">Profile</button> -->
    </mat-menu>
    <a mat-button [matMenuTriggerFor]="appProfile">
      <mat-icon>person</mat-icon>
      {{currentUser.Username}}
    </a>
  </mat-toolbar-row>
</mat-toolbar>
<!-- <pre>{{currentUser | json}}</pre> -->