<mat-divider></mat-divider>
<div>
    <mat-label>Slider</mat-label>
    <div style="text-align:center; margin-top: 100px; ">
        <mat-card style="margin-top:10px; width: 50%;">
            <mat-card-content>
                <ul>
                    <mat-chip-list #chipList>
                        <li *ngFor="let file of files; let i = index">
                            <!-- <mat-progress-bar [value]="file.progress"></mat-progress-bar> -->

                            <mat-card class="example-card">
                                <mat-card-header>
                                    <mat-chip [removable]="true" (removed)="remove(file)">
                                        {{file?.FileName}} - {{i}}
                                        <mat-icon matChipRemove>cancel
                                        </mat-icon>
                                    </mat-chip>
                                </mat-card-header>
                                <!-- mat-card-image -->
                                <img style="height:100px;width:100px" [src]="getUploadedFile(file.Path)" alt="my photo">
                                <mat-card-content>
                                    <!-- <p>
                                                                {{file?.data?.name}}
                                                            </p> -->
                                </mat-card-content>
                                <!-- <mat-card-actions>
                                                            <button mat-button>LIKE</button>
                                                            <button mat-button>SHARE</button>
                                                        </mat-card-actions> -->
                            </mat-card>
                        </li>
                    </mat-chip-list>


                </ul>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="warn" (click)="onClick()">
                    <mat-icon>file_upload</mat-icon>
                    Upload
                </button>
            </mat-card-actions>
        </mat-card><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*"
            style="display:none;" />
    </div>
</div>

<!-- <pre>{{files| json}}</pre> -->

<!-- <img height="100px" width="100px" [src]="_d.bypassSecurityTrustUrl(imgsrc)"> -->
<!-- G:\Work\Projects\DreptulLaViata-new\AAUpload -->
<!-- "G:\Work\Projects\DreptulLaViata-new\AAUpload\home-slider\fe93be8e43451cb9.jpg"
"G:\Work\Projects\DreptulLaViata-new\AAUpload\home-slider\4c052f559aab0f49.jpg" -->
<!-- <img style="height:100px;width:100px"
    src='G:\Work\Projects\DreptulLaViata-new\AAUpload\home-slider\4c052f559aab0f49.jpg' alt="my photo">
<img style="height:100px;width:100px" src="../../../../../AAUpload/home-slider/fe93be8e43451cb9.jpg" alt="my photo"> -->