<form #f="ngForm" name="theForm">
  <app-command-bar>
    <h1>Footer</h1>
  </app-command-bar>
  <app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="updatePage($event)">
      <mat-icon>save</mat-icon>
      {{ 'Save' }}
    </button>
  </app-command-bar>
  <mat-card>
    <mat-card-header>

    </mat-card-header>
    <mat-card-content>

      <div *ngIf="footerPage?.Content || editorContentLoaded">
        <ckeditor [editor]="editorContent" [data]="footerPage.Content" (change)="onContentChange($event)"></ckeditor>
      </div>
      <div *ngIf="footerPage?.Text1 || editorText1Loaded">
        <ckeditor [editor]="editorText1" [data]="footerPage.Text1" (change)="onText1Change($event)"></ckeditor>
      </div>
      <div *ngIf="footerPage?.Text2 || editorText2Loaded">
        <ckeditor [editor]="editorText2" [data]="footerPage.Text2" (change)="onText2Change($event)"></ckeditor>
      </div>
      <div *ngIf="footerPage?.Text3 || editorText3Loaded">
        <ckeditor [editor]="editorText3" [data]="footerPage.Text3" (change)="onText3Change($event)"></ckeditor>
      </div>

    </mat-card-content>
  </mat-card>
</form>