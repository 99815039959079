import { Component, Injectable } from '@angular/core';


@Injectable()
export class HtmlContentHelper {
    public trans;
    public lang;
    public properties;

    constructor() {

    }

    // // In ts file
    public getSafeUrl(content: string) {
        // let src = this.sanitizer.bypassSecurityTrustResourceUrl(this.editorValueCol1).toString();
        let src = content;
        src = src.replace('<p><iframe', '<iframe');
        src = src.replace('</iframe></p>', '</iframe>');

        src = src.replace('<p>&lt;iframe', '&lt;iframe');
        src = src.replace('&lt;/iframe&gt;</p>', '&lt;/iframe&gt;');
        src = src.replace('<h3>', '');
        src = src.replace('</h3>', '');

        return src;
    }
}

