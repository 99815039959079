<app-command-bar>
    <h1>Parteneri</h1>
</app-command-bar>
<app-command-bar>
    <button mat-button class="crm-command-bar-button" (click)="savePage()">
        <mat-icon>save</mat-icon>
        {{ 'Save' }}
    </button>
</app-command-bar>

<div *ngIf="partnerPage.Text1 || partnerPageMessage">
    <ckeditor [editor]="editor" [data]="partnerPage.Text1" (change)="onContentChange($event)"></ckeditor>
</div>

<app-command-bar>
    <button color="primary" mat-button class="crm-command-bar-button" (click)="createNew()">
        <mat-icon>add</mat-icon>
        {{ 'Nou' }}
    </button>
</app-command-bar>
<app-datatable [dataSource]="dataSource" [displayedColumns]="columns" [tableConfig]="tableConfig"
    (selected)="selected($event)" (filter)="onFilter($event)" (sort)="onSort($event)" (rowClicked)="rowClicked($event)"
    (columnFiltered)="onColumnFiltered($event)" (rowRightClicked)="rowRightClicked($event)" [data]="partners"
    [editDialog]="editDialog" [service]="partnerService">
</app-datatable>