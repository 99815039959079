export class PageModel {
    Id: number;
    Name: string;
    Motto: string;
    Priority: number;
    Text1: string;
    Text2: string;
    Text3: string;
    Content: string;
}
