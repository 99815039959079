import { Component, OnInit } from '@angular/core';
import { PartnerModel } from 'src/app/core/entities/models/partner.model';
import { PartnerService } from 'src/app/core/services/partner.service';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { PageModel } from '../../core/entities/models/page.model';
import { PageService } from '../services/PagesServices';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  public partnerPage: PageModel;
  public partners: PartnerModel[] = [];

  constructor(
    private pageService: PageService,
    private partnerService: PartnerService


  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  public loadData() {
    // about page
    this.pageService.findOne(Pages.Partner).subscribe(
      res => {
        this.partnerPage = res;
      });
    this.partnerService.findAll().subscribe(
      res => {
        this.partners = res;
      });
  }
}
