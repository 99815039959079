import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Pages } from '../shared/StandardEnum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerPage: PageModel;
  editorValueCol1: string;
  editorValueCol2: string;
  editorValueCol3: string;
  today: number = Date.now();
  year: number;

  constructor(private pageService: PageService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getData();
    this.year = this.today;
  }

  getData() {
    this.pageService.findOne(Pages.Footer).subscribe(
      res => {
        this.editorValueCol1 = res.Text1;
        this.editorValueCol2 = res.Text2;
        this.editorValueCol3 = res.Text3;
        // this.editorValueCol1 = this.editorValueCol1.replace('<p>', '');
        // this.editorValueCol1 = this.editorValueCol1.replace('</p>', '');


      });
  }

  // // In ts file
  // getSafeUrl() {
  //   // let src = this.sanitizer.bypassSecurityTrustResourceUrl(this.editorValueCol1).toString();
  //   let src = this.editorValueCol1;
  //   src = src.replace('<p>', '');
  //   src = src.replace('</p>', '');
  //   return src;
  // }
}
