import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../frontoffice/services/AuthenticateService';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { User } from 'src/app/core/entities/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    // public theBoolean: BehaviorSubject<boolean>;
    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     const currentUser = this.authenticationService.currentUserValue;
    //     if (currentUser) {
    //         // logged in so return true
    //         return true;
    //     }

    //     // not logged in so redirect to login page with the return url
    //     this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //     return false;
    // }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.Id > 0) {
            // logged in so return true
            this.loggedIn.next(true);

            return this.loggedIn.asObservable();
        }
        return this.authenticationService.isLoggedIn         // {1}
            .pipe(
                take(1),                                // {2}
                map((isLoggedIn: boolean) => {         // {3}
                    if (!isLoggedIn) {
                        this.router.navigate(['/login']);  // {4}
                        return false;
                    }
                    return true;
                }));
    }
}
