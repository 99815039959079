<!-- <p-galleria *ngIf="images" [images]="images" panelWidth="100%" panelHeight="700" [showCaption]="false"></p-galleria> -->
<!-- max-width: 70%; margin-left: 15%; margin-right: 15% -->
<div class="container-wrapper">
    <mat-card class="home-card">
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>
            <!--  -->
            <div *ngIf="page">
                <div [innerHTML]="page.Motto | keepHtml"></div>
            </div>
        </mat-card-content>
        <!-- <mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
        </mat-card-actions> -->
    </mat-card>
    <mat-carousel class="images-carousel" timings="250ms ease-in" [autoplay]="true" interval="3000" color="accent"
        maxWidth="auto" proportion="50" slides="5" [loop]="true" [hideArrows]="false" [hideIndicators]="false"
        [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of pictures; let i = index" [image]="slide.Path"
            overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
</div>