import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepHtml', pure: false })
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content) {
    // let src = content;
    // src = src.replaceAll('<oembed url', '<iframe class ="app-media-video" src');
    // src = src.replaceAll('</oembed>', '</iframe>');

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}


// function getId(url) {
//   var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
//   var match = url.match(regExp);

//   if (match && match[2].length == 11) {
//     return match[2];
//   } else {
//     return 'error';
//   }
// }

// var videoId = getId('http://www.youtube.com/watch?v=zbYf5_S7oJo');

// var iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
//   + videoId + '" frameborder="0" allowfullscreen></iframe>';