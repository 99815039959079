<form #form="ngForm" class="description-card container-wrapper">

  <app-command-bar>
    <h1>Cum donăm? - Donaţii şi sponsorizări</h1>
  </app-command-bar>
  <mat-card>
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <!--  -->
      <div ng-view="">
        <div [innerHTML]="editorContent | keepHtml"></div>
      </div>
    </mat-card-content>
  </mat-card>
</form>