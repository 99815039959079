import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/frontoffice/services/AuthenticateService';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private loginService: AuthenticationService) { }


  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return next.handle(request);
  // }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var token = JSON.parse(localStorage.getItem('access_token'));
    // add authorization header with jwt token if available
    let currentUser = this.loginService.currentUserValue;
    if (token) { //.token
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token} `  //`Bearer ${currentUser}`//.token//`Bearer supersecretkeyyoushouldnotcommittogithub` //
        }
      });
    }

    return next.handle(request);
  }
}
