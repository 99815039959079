import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { AlertService } from 'src/app/core/services/alert.service';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-contact',
  templateUrl: './admin-contact.component.html',
  styleUrls: ['./admin-contact.component.scss']
})
export class AdminContactComponent implements OnInit {
  public editorText = ClassicEditor;
  public editorTextLoaded = false;

  public contactPage: PageModel = new PageModel();
  constructor(private pageService: PageService, private alertService: AlertService) {
  }

  public ngOnInit() {
    this.loadData();
  }
  public loadData() {
    this.pageService.findOne(Pages.Contact).subscribe(
      res => {
        this.contactPage = res;
        this.editorTextLoaded = true;
      });
  }
  public onTextChange({ editor }: ChangeEvent) {
    if (editor) {
      this.contactPage.Text1 = editor.getData();
      if (!this.contactPage.Text1 || this.contactPage.Text1.length === 0) {
        this.contactPage.Text1 = " ";
      }
    }
  }
  public updatePage(event) {
    this.pageService.update(Pages.Contact, this.contactPage)
      .subscribe((res) => {
        this.alertService.success('Pagina a fost updatata cu success!');
      },
        (err) => this.alertService.error(err));
  }
}
