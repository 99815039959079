import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ILoaderState } from '../interfaces/ILoaderState';

@Directive()
@Injectable()
export class SpinnerService {
    @Output() open: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();

    private loaderSubject = new Subject<ILoaderState>();
    private visible = true;

    // A BehaviorSubject is an Observable with a default value
    public isLoading = new BehaviorSubject(false);

    public spinnerActive: EventEmitter<Boolean>;
    loaderState = this.loaderSubject.asObservable();
    constructor() {
        this.spinnerActive = new EventEmitter();
    }

    show() {
        return this.isLoading;
    }
    hide() {
        return this.isLoading;

    }
}
