<div class="registerPage">
  <mat-card>
    <mat-card-content>
      <form class="registerForm" #values="ngForm">
        <mat-form-field class="full-width">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First name" name="fname" required>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" name="lname" required>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" name="address" required>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" name="email">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" name="password">
        </mat-form-field>
        <section class="example-section">
          <label class="example-margin">Gender:</label>
          <!-- <mat-radio-group [(ngModel)]="gender" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-margin" value="after">Male</mat-radio-button>
            <mat-radio-button class="example-margin" value="before">Female</mat-radio-button>
            <mat-radio-button class="example-margin" value="before">Other</mat-radio-button>
          </mat-radio-group> -->
        </section>

        <mat-form-field>
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Choose a date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="register()" color="primary">REGISTER</button>
    </mat-card-actions>
  </mat-card>
</div>