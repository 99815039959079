import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/frontoffice/services/AuthenticateService';
import { Router, NavigationEnd } from '@angular/router';
import { User } from 'src/app/core/entities/models/user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-toolbar',
  templateUrl: './user-toolbar.component.html',
  styleUrls: ['./user-toolbar.component.scss']
})
export class UserToolbarComponent implements OnInit {
  public isAdmin: number;
  public currentUser: User;
  public isLoggedIn: Observable<boolean>;                  // {1}
  public logged: Boolean;

  constructor(private authService: AuthenticationService, private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {

        this.currentUser = this.authService.currentUserValue;
        this.authService.isLoggedIn.subscribe((logged) => {
          this.logged = logged;
        });
        if (this.currentUser && this.logged === true) {
          this.isLoggedIn = this.authService.isLoggedIn; // {2}
          this.isAdmin = this.currentUser.IsAdmin;
          return true;
        }
      }
    });
  }

  ngOnInit() {
  }
  onLogout() {
    this.authService.logout();                      // {3}
  }

  public navigate(url: string): void {
    if (url) {
      this.router.navigate([url]);
    }
  }
}
