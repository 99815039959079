import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Settings } from '../../core/app.settings';
import { catchError, map } from 'rxjs/operators';
import { PageModel } from '../../core/entities/models/page.model';
import { CommonService } from './CommonService';
import { DataService } from '../../core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class PageService extends DataService<PageModel, number> {
    private pages: PageModel[];
    header: any;

    private settings = new Settings();
    public headers: HttpHeaders = new HttpHeaders();


    constructor(protected _http: HttpClient, private commonService: CommonService) {
        super(_http, `${environment.api.baseUrl}/pages`);
        const headerSettings: { [name: string]: string | string[]; } = {};
        this.header = new HttpHeaders(headerSettings);

    }


    public addPage(page: PageModel): Observable<PageModel[]> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        const t = localStorage.getItem('access_token');
        headers.append('Authorization', 'Bearer ' + t);
        return this._http.post(this.settings.pagesUrl, { data: page }, { headers: headers })
            .pipe(map((res: PageModel) => {
                this.pages.push(res);
                return this.pages;
            }),
                catchError(this.commonService.handleError));
    }
    // getPageById(id: number): Observable<PageModel> {
    //     const params = new HttpParams()
    //         .set('Id', id.toString());
    //     return this._http.get(this.settings.pagesUrl, { params: params })
    //         .pipe(
    //             map((res: PageModel) => {
    //                 return res;
    //             }), catchError(this.commonService.handleError));

    // }
    getPages(): Observable<PageModel[]> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        const t = localStorage.getItem('access_token');
        headers.append('Authorization', 'Bearer ' + t);
        return this._http.get(this.settings.pagesUrl, { headers: headers })
            .pipe(
                map((res: PageModel[]) => {
                    this.pages = res; // res['data'];
                    return this.pages;
                }), catchError(this.commonService.handleError));

    }

    deletePage(id: number): Observable<PageModel[]> {
        const deleteUrl = this.settings.pagesUrl;

        const params = new HttpParams()
            .set('Id', id.toString());

        return this._http.delete(deleteUrl, { params: params })
            .pipe(map(() => {
                const filteredPages = this.pages.filter((page) => {
                    return +page['Id'] !== +id;
                });
                return this.pages = filteredPages;
            }),
                catchError(this.commonService.handleError));
    }

    updatePage(pageModel: PageModel): Observable<PageModel[]> {
        const url = this.settings.pagesUrl;
        return this._http.put(url, { data: pageModel })
            .pipe(map(() => {

                const page = this.pages.find((item) => {
                    return +item['id'] === +pageModel['id'];
                });
                if (page) {
                    page.Motto = pageModel.Motto;
                    page.Text1 = pageModel.Text1;
                    page.Text2 = pageModel.Text2;
                    page.Text3 = pageModel.Text3;
                }
                return this.pages;
            }),
                catchError(this.commonService.handleError));
    }
    updateOnePage(pageModel: PageModel): Observable<PageModel> {
        const url = this.settings.pagesUrl;
        // let page = new PageModel;
        return this._http.put(url, { data: pageModel })
            .pipe(map(() => {
                // page = this.pages.find((item) => {
                //     return +item['id'] === +pageModel['id'];
                // });
                if (pageModel) {
                    pageModel.Motto = pageModel.Motto;
                    pageModel.Text1 = pageModel.Text1;
                    pageModel.Text2 = pageModel.Text2;
                    pageModel.Text3 = pageModel.Text3;
                    pageModel.Content = pageModel.Content;

                }
                return pageModel;
            }),
                catchError(this.commonService.handleError));
    }
}
