import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnConfig, filedType, TableConfig } from 'src/app/core/components/datatable/utils/column-config';
import { VideoArticleModel } from 'src/app/core/entities/models/video-article.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { VideoArticleService } from 'src/app/core/services/video-article.service';
import { AdminArticleDialogComponent } from './admin-article-dialog/admin-article-dialog.component';

@Component({
  selector: 'app-admin-article',
  templateUrl: './admin-article.component.html',
  styleUrls: ['./admin-article.component.scss']
})
export class AdminArticleComponent implements OnInit {
  @Input() public selection = new SelectionModel<VideoArticleModel>(true, []);

  public editDialog = AdminArticleDialogComponent;
  public videoArticles: VideoArticleModel[] = [];

  public dataSource = new MatTableDataSource<VideoArticleModel>(this.videoArticles);
  public partnerPageMessage: string = '';
  public columns: ColumnConfig[] = [

    {
      name: 'Name',
      displayName: 'Nume',
      type: filedType.string,
    },

    {
      name: 'Description',
      displayName: 'Description',
      type: filedType.string,
    },
    {
      name: 'URL',
      displayName: 'URL',
      type: filedType.string,
    },

  ];

  public tableConfig: TableConfig = {
    showCheckAll: false,
    showSearchInput: false,
    showFilterInputOnHeader: false,
    showFilter: false,
  };
  constructor(public videoArticleService: VideoArticleService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public alertService: AlertService,
  ) { }

  public ngOnInit(): void {
    this.loadData();
  }

  public createNew(): void {
    this.openDialog(new VideoArticleModel());
  }

  public loadData(): void {
    this.videoArticleService.findAll()
      .subscribe((videoArticles) => {
        this.videoArticles = videoArticles;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource<VideoArticleModel>(this.videoArticles);
      });
  }


  public openDialog(obj: VideoArticleModel): void {
    if (this.editDialog) {
      const dialogRef = this.dialog.open(this.editDialog, {
        width: '750px',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '40vh',
        data: {
          model: obj,
          action: 'Save',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result || result.event === 'Cancel') {
          return;
        }
        if (result.data) {
          if (!result.data.Id || result.data.Id === 0) {
            this.addRowData(result.data);
          }
          else {
            this.updateRowData(result.data);
          }
        }
      });
    }
  }

  public addRowData(row: VideoArticleModel): void {
    this.videoArticleService.create(row)
      .subscribe(
        () => {
          this.alertService.success(this.translateService.instant('GENERIC.SUCCES_CONFIRMATION'));

          this.loadData();
        },
        (error) => {
          this.alertService.error(error);
        },
      );
  }
  public updateRowData(row: VideoArticleModel): void {
    this.videoArticleService.update(row.Id, row)
      .subscribe(
        () => {
          this.alertService.success(this.translateService.instant('GENERIC.SUCCES_CONFIRMATION'));

          this.loadData();
        },
        (error) => {
          this.alertService.error(error);
        },
      );
  }

  public rowRightClicked(event): void {
    console.log(event);
  }

  public selected(selected): void {
    selected.map((row) => {
      this.selection.toggle(row);
    });
  }

  public onFilter(event): void {

  }

  public onSort(event): void {
    console.log(event);
  }

  public rowClicked(event): void {
    console.log(event);
  }

  public onColumnFiltered(event): void {
    console.log(this.columns);
    console.log(event);
  }

}
