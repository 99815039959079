import { Component, OnInit } from '@angular/core';
// import { UserService } from 'src/app/components/core/services/user.service';
// import { ProfileModel } from 'src/app/models/profile.model';


@Component({
  selector: 'app-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  // providers: [UserService]
})
export class AdminProfileComponent implements OnInit {
  public userProfile: [] = [];
  // public Editor1 = ClassicEditor;
  // public Editor2 = ClassicEditor;

  public editorDescriptionValue = '';
  public editorProgramValue = '';


  constructor() { }

  ngOnInit(): void {
    this.loadData();
    this.setEditor();
  }
  public setEditor() {

    // ClassicEditor
    //   .create(document.querySelector('#editor'))
    //   .then(editor => {
    //     console.log(editor);
    //   })
    //   .catch(error => {

    //     console.error(error);
    //   });

    // ClassicEditor
    //   .create(document.querySelector('#editor'), {
    //     toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    //     heading: {
    //       options: [
    //         { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    //         { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
    //         { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
    //       ]
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  public loadData(): void {
    // this.userService.getUserProfile(1).subscribe(profile => {
    //   this.userProfile = profile;
    //   this.editorDescriptionValue = profile.Descriere;
    //   this.editorProgramValue = profile.Program;
    // });
  }

  public save(): void {
    // this.userProfile.Descriere = this.editorDescriptionValue;
    // this.userProfile.Program = this.editorProgramValue;

    // this.userService.updateUserProfile(this.userProfile)
    //   .subscribe(
    //     (res) => {
    //       // this.pages = res;
    //       // this.success = 'Updated successfully';
    //     }
    //   );
    //   this.toastrService.success('Motto a fost salvat cu succes.', 'Succes!', this.toastrConfig);
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  // public onDescriptionChange({ editor }: ChangeEvent) {
  //   this.editorDescriptionValue = editor.getData();
  //   console.log(this.editorDescriptionValue);
  // }
  // public onProgramChange({ editor }: ChangeEvent) {
  //   this.editorProgramValue = editor.getData();
  //   console.log(this.editorProgramValue);
  // }

}
