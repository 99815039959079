import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { HtmlContentHelper } from '../shared/HtmlContentHelper';
import { ImagesService } from 'src/app/frontoffice/services/ImagesService';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { AlertService } from 'src/app/core/services/alert.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-admin-center',
  templateUrl: './admin-center.component.html',
  styleUrls: ['./admin-center.component.scss']
})
export class AdminCenterComponent implements OnInit {
  @ViewChild('editor') editor: ElementRef;
  public modules = {};
  public editorOptions = {};
  public helper: HtmlContentHelper;
  public images = [];

  public pagesEnum = Pages;


  public editorContent = ClassicEditor;
  public contentLoaded = false;

  public centerPage: PageModel;
  toastrConfig: { positionClass: string; };
  constructor(
    private pageService: PageService
    , private alertService: AlertService
    , htmlContentHelper: HtmlContentHelper
    , private imagesService: ImagesService
    // , private confirmationService: ConfirmationService
  ) {
    this.helper = htmlContentHelper;
  }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    // about page

    this.imagesService.findAll(Pages.Center.toString())
      .subscribe(
        res => {
          this.images = res;
        });

    this.pageService.findOne(Pages.Center).subscribe(
      res => {
        this.centerPage = res;
      });
  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.centerPage.Text1 = editor.getData().replace("<p>", "").replace("</p>", "");
      if (!this.centerPage.Text1 || this.centerPage.Text1.length === 0) {
        this.centerPage.Text1 = " ";
      }
    }
  }
  // updatePage(event) {
  //   this.centerPage.Content = this.editorContent;

  //   this.pageService.updateOnePage(this.centerPage)
  //     .subscribe((res) => {
  //       this.centerPage = res;

  //       this.alertService.success('Pagina a fost updatata cu success!');
  //     },
  //       (err) => this.alertService.error(err));

  // }

  public updatePage() {
    this.pageService.update(Pages.Center, this.centerPage)
      .subscribe((res) => {
        this.centerPage = res;
        this.alertService.success('Pagina a fost updatata cu success!');
        this.loadData();
      },
        (err) => {
          if (err) {
            this.alertService.error(err)
          }
        }
      );
  }

  validateChange(event, content: string) {
    const html = event.htmlValue;
    if (html.includes('&lt;iframe') || html.includes('<iframe')) {
      content = this.helper.getSafeUrl(html);
      // this.editor.nativeElement.value = this.text1;
    }
  }



}
