import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-partner-dialog',
  templateUrl: './admin-partner-dialog.component.html',
  styleUrls: ['./admin-partner-dialog.component.scss']
})
export class AdminPartnerDialogComponent implements OnInit {
  public action: string;
  public model: any;

  constructor(
    public dialogRef: MatDialogRef<AdminPartnerDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = { ...data.model };
    this.action = data.action;
  }
  public ngOnInit(): void {

  }

  public doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.model });
  }

  public closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
