import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService } from '../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(private pageService: PageService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.findOne(Pages.About).subscribe(
      () => {
      });
  }
  ngAfterViewInit(): void {
  }
  ngOnDestroy() {
  }
}
