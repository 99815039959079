import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PageModel } from 'src/app/core/entities/models/page.model';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { ToastrService } from 'ngx-toastr';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { AlertService } from 'src/app/core/services/alert.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TeamModel } from 'src/app/core/entities/models/team.model';
import { TeamsService } from 'src/app/core/services/teams.service';

@Component({
  selector: 'app-admin-christmas',
  templateUrl: './admin-christmas.component.html',
  styleUrls: ['./admin-christmas.component.scss']
})
export class AdminChristmasComponent implements OnInit {
  public pageText?: string;
  public team: TeamModel[];
  public christmasPageMessage: string = '';
  public editorContent = ClassicEditor;
  public editorText = ClassicEditor;
  public editorContentLoaded = false;
  public editorTextLoaded = false;


  public christmasPage: PageModel = new PageModel();
  constructor(
    private pageService: PageService
    , private toastrService: ToastrService
    , private teamService: TeamsService
    , public dialog: MatDialog
    , public alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.getTeam();
    this.loadData();
  }
  getTeam() {
    // about page
    this.teamService.findAll().subscribe(
      res => {
        this.team = res;

      });
  }
  public loadData() {
    // about page
    this.pageService.findOne(Pages.Christmas).subscribe(
      res => {
        this.christmasPage = res;
        this.editorContentLoaded = true;
        this.editorTextLoaded = true;
      });
  }

  public onTextChange({ editor }: ChangeEvent) {
    if (editor) {
      this.christmasPage.Text1 = editor.getData();
      if (!this.christmasPage.Text1 || this.christmasPage.Text1.length === 0) {
        this.christmasPage.Text1 = " ";
      }
    }

  }

  public onContentChange({ editor }: ChangeEvent) {
    if (editor) {
      this.christmasPage.Content = editor.getData();
      if (!this.christmasPage.Content || this.christmasPage.Content.length === 0) {
        this.christmasPage.Content = " ";
      }
    }
  }

  public updatePage(event) {
    this.pageService.update(Pages.Christmas, this.christmasPage)
      .subscribe((res) => {
        this.alertService.success('Pagina a fost updatata cu success!');
      },
        (err) => {
          if (err) {
            this.alertService.error(err.error);
          }
        });
  }


}
