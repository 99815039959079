import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';

import { BackofficeRoutingModule } from './backoffice-routing.module';
import { AppMaterialModule } from '../core/material/app.material';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminAboutComponent } from './admin-about/admin-about.component';
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { AdminChildrenComponent } from './admin-children/admin-children.component';
import { AdminChristmasComponent } from './admin-christmas/admin-christmas.component';
import { AdminContactComponent } from './admin-contact/admin-contact.component';
import { AdminDonateComponent } from './admin-donate/admin-donate.component';
import { AdminProjectsComponent } from './admin-projects/admin-projects.component';
import { AdminCenterComponent } from './admin-center/admin-center.component';

// services
import { NodeService } from '../core/shared/node.service';
import { HtmlContentHelper } from './shared/HtmlContentHelper';
// import { HomeSettingsComponent } from './administration/administration.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminPartnerComponent } from './admin-partner/admin-partner.component';
import { AdminPartnerDialogComponent } from './admin-partner/admin-partner-dialog/admin-partner-dialog.component';
import { SharedModule } from '../core/modules/shared.module';
import { AdminTeamComponent } from './admin-about/admin-team/admin-team.component';
import { AdminTeamDialogComponent } from './admin-about/admin-team/admin-team-dialog/admin-team-dialog.component';
import { ConfirmationDialogComponent } from '../core/components/confirmation-dialog/confirmation-dialog.component';
import { AdminArticleComponent } from './admin-about/admin-article/admin-article.component';
import { AdminArticleDialogComponent } from './admin-about/admin-article/admin-article-dialog/admin-article-dialog.component';
import { VideoArticleService } from '../core/services/video-article.service';
import { AdminMediaComponent } from './admin-about/admin-media/admin-media.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    // HomeSettingsComponent,
    AdminHomeComponent,
    AdminUsersComponent,
    AdminAboutComponent,
    AdminFooterComponent,
    AdminChildrenComponent,
    AdminChristmasComponent,
    AdminContactComponent,
    AdminDonateComponent,
    AdminProjectsComponent,
    AdminCenterComponent,
    AdminPartnerComponent,
    AdminPartnerDialogComponent,
    AdminTeamComponent,
    AdminTeamDialogComponent,
    ConfirmationDialogComponent,
    AdminArticleComponent,
    AdminArticleDialogComponent,
    AdminMediaComponent,
    FileUploadComponent,
    AdminProfileComponent
  ],
  imports: [
    BackofficeRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule
    // JwtModule.forRoot(JWT_Module_Options)

  ],
  exports: [
    AppMaterialModule,
    BackofficeRoutingModule,
    RouterModule
  ],
  providers: [
    NodeService,
    HtmlContentHelper,

  ]
})
export class BackofficeModule { }
