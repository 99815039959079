<mat-card class="description-card container-wrapper">
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <!--  -->
    <div *ngIf="centerPage?.Text1 ">
      <div [innerHTML]="centerPage.Text1 | keepHtml"></div>
    </div>

    <div class="gallerize-example">
      <h2>Gallerize Example</h2>
      <p class="gallery-container" gallerize>
        <img *ngFor="let img of images" [src]="img.Path">
      </p>
    </div>
  </mat-card-content>
</mat-card>
<!-- here
<pre>{{images1 | json}}</pre> -->