<div *ngIf="filterComponent" fxLayoutAlign="end start">
    <div class="rightSidenav_toggle" fxLayoutAlign="end end">
        <mat-icon (click)="sidenav.toggle()">
            filter_alt
        </mat-icon>
    </div>
</div>

<!-- <mat-sidenav-container class="sidenav-container" position="end">
    <mat-sidenav #sidenav mode="side" opened="false" class="sidenav" position="end" [fixedTopGap]="50">
        <app-base-section-bar>
            <h1 mat-dialog-title> Filtre </h1>
        </app-base-section-bar>
        <ng-container #tabsPlaceholder add-comp [component]="filterComponent">
        </ng-container>
    </mat-sidenav>
    <mat-sidenav-content> -->
<div>
    <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async"> -->
    <!-- <mat-spinner></mat-spinner> -->
    <!-- </div> -->
    <!-- <div *ngIf="loaderService.isLoading | async">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div> -->


    <div class="mat-elevation-z8" *ngIf="dataSource.data.length>0" fxLayoutGap="10px">
        <div *ngIf="tableConfig.showSearchInput">
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex="100" fxLayoutGap="20px" fxLayoutAlign="start start">
                    <mat-form-field>
                        <!-- onFilter($event.target.value) -->
                        <input matInput name="search" autocomplete="off" (keyup)="applyFilter($event.target.value)"
                            placeholder="Filter">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [matSortActive]="activeSort.name" [matSortDirection]="activeSort.sortActive">

            <ng-container matColumnDef="select">
                <mat-header-cell mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="onSelectMaster($event)" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" data-label="Select">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="onSelectRow($event, row)"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container *ngFor="let column of displayedColumns; let i = index" [matColumnDef]="column.name">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isSort">
                    <div fxLayout="column">
                        <div fxLayout="row">
                            <strong> {{ column.displayName }}</strong>
                        </div>
                        <div fxLayout="row" *ngIf="tableConfig.showFilterInputOnHeader">
                            <mat-form-field>
                                <input matInput placeholder={{column.displayName}} [(ngModel)]="filterEntity.Name">
                            </mat-form-field>
                        </div>

                    </div>


                    <div *ngIf="column.type==='enum'">
                        <button mat-button [matMenuTriggerFor]="menu">filter me</button>
                        <mat-menu #menu="matMenu">
                            <div *ngFor="let option of column.options">
                                <mat-checkbox (change)="onColumnFilter(column, option, $event)"
                                    (click)="$event.stopPropagation()" [checked]="option.selected">
                                    {{option.displayName}}
                                </mat-checkbox>
                            </div>
                        </mat-menu>
                    </div>
                </mat-header-cell>
                <!-- {{getColumnLabel(column)}}
                        {{ column.displayName }} -->
                <!-- getColumnLabel(column) -->
                <mat-cell mat-cell *matCellDef="let row" (click)="rowClick(row)" (contextmenu)="rightClick(row)"
                    [attr.data-label]="column.name" [ngClass]="{ 'selected': selection.isSelected(row)}">
                    <!-- {{ column.displayName }} -->
                    <div
                        *ngIf="column.type!=='component' && column.type!=='phone' && column.type!=='email' && column.type!=='file'">
                        {{row[column.name]}}</div>
                    <div *ngIf="column.type==='component'">
                    </div>
                    <div *ngIf="column.type==='phone'">
                        <a color="primary" class="a-button" (click)="$event.stopPropagation();"
                            href="tel:{{row[column.name]}}">
                            <span>{{row[column.name]}}
                            </span>
                        </a>
                    </div>
                    <div *ngIf="column.type==='email'">
                        <a color="primary" class="a-button" (click)="$event.stopPropagation();"
                            href="mailto:{{row[column.name]}}">
                            <span>{{row[column.name]}}
                            </span>
                        </a>
                    </div>
                    <div *ngIf="column.type==='file'">
                        <button mat-icon-button matTooltip="Descarca" class="iconbutton" color="primary"
                            (click)="download(row);$event.stopPropagation();">
                            <mat-icon aria-label="Descarca">cloud_download</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action-link" data-label="Action">
                    <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" color="primary"
                        (click)="openDialog('Update',element)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" color="warn"
                        (click)="confirmDialog(element)">
                        <mat-icon aria-label="Delete">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="col"></mat-header-row>
            <mat-row class="element-row" *matRowDef="let row; columns: col;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
            [length]="totalSize" [pageIndex]="currentPage" (page)="handlePage($event)">
        </mat-paginator>
    </div>

</div>
<!-- <app-registrations-diploma></app-registrations-diploma> -->
<!-- </mat-sidenav-content>
</mat-sidenav-container> -->
<!-- <pre>{{dataSource.data | json}}</pre> -->