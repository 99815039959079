import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactModel } from '../models/ContactModel';
import { CommonService } from './CommonService';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  ServerUrl = 'http://localhost/dev/blogger/';
  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient
    , private commonService: CommonService
    ) { }

  //   getPage(slug: string) {
  //     return this.http.get<Page>(this.ServerUrl + 'api/page/' + slug)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  //   }

  contactForm(formdata: ContactModel) {
    return this.http.post<ContactModel>(this.ServerUrl + 'api/contact', formdata, this.httpOptions).pipe(
      catchError(this.commonService.handleError)
    );
  }
}
