import { Component, OnInit, ViewChild } from '@angular/core';
import { PageService } from '../services/PagesServices';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { ImagesService } from '../services/ImagesService';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { ImageModel } from '../models/ImageModel';
@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss']
})
export class CenterComponent implements OnInit {

  public images: ImageModel[] = [];
  public centerPage: PageModel;


  constructor(private pageService: PageService
    , private imagesService: ImagesService
  ) { }


  ngOnInit() {
    this.loadData();
  }
  public loadData(): void {

    // about page
    this.pageService.findOne(Pages.Center).subscribe(
      res => {
        this.centerPage = res
      });
    this.imagesService.findAll(Pages.Center.toString())
      .subscribe(
        res => {
          this.images = res;
        }
      );
  }
}
