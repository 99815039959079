import { Component, Input, OnInit } from '@angular/core';
import { TeamModel } from 'src/app/core/entities/models/team.model';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnConfig, filedType, TableConfig } from 'src/app/core/components/datatable/utils/column-config';
import { PageModel } from 'src/app/core/entities/models/page.model';
import { AdminTeamDialogComponent } from './admin-team-dialog/admin-team-dialog.component';
import { TeamsService } from 'src/app/core/services/teams.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-admin-team',
  templateUrl: './admin-team.component.html',
  styleUrls: ['./admin-team.component.scss']
})
export class AdminTeamComponent implements OnInit {
  @Input() public selection = new SelectionModel<TeamModel>(true, []);

  public editDialog = AdminTeamDialogComponent;
  public teamPage: PageModel = new PageModel();
  public teams: TeamModel[] = [];
  public editor = ClassicEditor;

  public dataSource = new MatTableDataSource<TeamModel>(this.teams);
  public partnerPageMessage: string = '';
  public columns: ColumnConfig[] = [

    {
      name: 'Name',
      displayName: 'Companie',
      type: filedType.string,
    },

    {
      name: 'Description',
      displayName: 'Description',
      type: filedType.string,
    },
    {
      name: 'Picture',
      displayName: 'Poza',
      type: filedType.string,
    },

  ];

  public tableConfig: TableConfig = {
    showCheckAll: false,
    showSearchInput: false,
    showFilterInputOnHeader: false,
    showFilter: false,
  };
  constructor(public teamsService: TeamsService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public alertService: AlertService,
  ) { }

  public ngOnInit(): void {
    this.loadData();
  }

  public createNew(): void {
    this.openDialog(new TeamModel());
  }

  public loadData(): void {
    this.teamsService.findAll()
      .subscribe((teams) => {
        this.teams = teams;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource<TeamModel>(this.teams);
      });
  }


  public openDialog(obj: TeamModel): void {
    if (this.editDialog) {
      const dialogRef = this.dialog.open(this.editDialog, {
        width: '750px',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '40vh',
        data: {
          model: obj,
          action: 'Save',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result || result.event === 'Cancel') {
          return;
        }
        if (result.data) {
          this.addRowData(result.data);
        }
      });
    }
  }

  public addRowData(row: TeamModel): void {
    this.teamsService.create(row)
      .subscribe(
        () => {
          this.alertService.success(this.translateService.instant('GENERIC.SUCCES_CONFIRMATION'));

          this.loadData();
        },
        (error) => {
          this.alertService.error(error);
        },
      );
  }


  public rowRightClicked(event): void {
    console.log(event);
  }

  public selected(selected): void {
    selected.map((row) => {
      this.selection.toggle(row);
    });
  }

  public onFilter(event): void {

  }

  public onSort(event): void {
    console.log(event);
  }

  public rowClicked(event): void {
    console.log(event);
  }

  public onColumnFiltered(event): void {
    console.log(this.columns);
    console.log(event);
  }

}
