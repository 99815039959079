import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Settings } from '../../core/app.settings';
import { catchError, map } from 'rxjs/operators';
import { ImageModel } from '../models/ImageModel';
import { CommonService } from './CommonService';
import { DataService } from '../../core/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImagesService extends DataService<ImageModel, number> {

    header: any;

    private images: ImageModel[];
    private settings = new Settings();
    public headers: HttpHeaders = new HttpHeaders();

    // constructor(private http: HttpClient, private spinnerService: SpinnerService, private commonService: CommonService) {
    //     // this.headers.append('Access-Control-Allow-Origin', '*');
    // }

    constructor(protected _http: HttpClient, private commonService: CommonService) {
        super(_http, `${environment.api.baseUrl}/images`);
        const headerSettings: { [name: string]: string | string[]; } = {};
        this.header = new HttpHeaders(headerSettings);

    }

    addImage(image: ImageModel, files: any): Observable<ImageModel[]> {
        const uploadFormData = new FormData();
        uploadFormData.append('myFile', files, files.name);
        uploadFormData.append('pageId', image.PageId.toString());

        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data; charset=utf-8');
        // headers.set('Content-length', params[0].length);
        return this._http.post(this.settings.imagesUrl, uploadFormData)
            .pipe(map((res: ImageModel) => {
                this.images.push(res);
                return this.images;
            }),
                catchError(this.commonService.handleError));
    }

    getImages(pageid: number): Observable<ImageModel[]> {

        // const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        // const t = localStorage.getItem('access_token');
        // headers.append('Authorization', 'Bearer ' + t);
        const params = new HttpParams().set('PageId', pageid.toString());
        return this._http.get(this.settings.imagesUrl, { params: params })
            .pipe(
                map((res: ImageModel[]) => {
                    this.images = res; // res['data'];
                    return this.images;
                }), catchError(this.commonService.handleError));

    }

    deleteImage(id: number): Observable<ImageModel[]> {
        const deleteUrl = this.settings.deleteimage;

        const params = new HttpParams()
            .set('Id', id.toString());

        return this._http.delete(deleteUrl, { params: params })
            .pipe(map(() => {
                const filteredImages = this.images.filter((image) => {
                    return +image['Id'] !== +id;
                });
                return this.images = filteredImages;
            }),
                catchError(this.commonService.handleError));
    }
}
