import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UploadService } from 'src/app/core/services/upload.service';
import { Pages } from 'src/app/core/shared/StandardEnum';
import { ImageModel } from 'src/app/frontoffice/models/ImageModel';
import { ImagesService } from 'src/app/frontoffice/services/ImagesService';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @Input() public files = [];
  @Input() public pageId: Pages = 0;

  constructor(private uploadService: UploadService, private imagesService: ImagesService) { }

  ngOnInit(): void {
  }

  public onClick(): void {
    // const file = event && event.target.files[0];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }
  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  public remove(file: ImageModel): void {
    const fileExists = this.files.find(element => element.Id === file.Id);

    if (fileExists) {
      this.imagesService.delete(file.Id).subscribe(
        () => {

          const index = this.files.indexOf(fileExists);
          if (index >= 0) {
            this.files.splice(index, 1);
          }
        }
      );
    }
  }

  public getUploadedFile(filePath: string): string {
    filePath = filePath.replace("../../app.dreptullaviata.ro/", "");
    filePath = filePath.replace("../../dreptullaviata.ro/", "");

    return filePath;
  }

  public uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    let folderSuffix = "home-slider";

    // const options = this.getFormUrlEncoded(file.data)
    switch (this.pageId) {
      case Pages.Home: {
        folderSuffix = "home-slider";
        break;
      }
      case Pages.Center: {
        folderSuffix = "center-gallerry";
        break;
      }
      default: {
        folderSuffix = "home-slider";

        break;
      }
    }
    this.uploadService.upload(formData, folderSuffix)
      // .pipe(
      //   map(event => {

      //     switch (event.type) {
      //       case HttpEventType.UploadProgress:
      //         file.progress = Math.round(event.loaded * 100 / event.total);
      //         break;
      //       case HttpEventType.Response:
      //         return event;
      //       default:
      //         return event;
      //     }
      //   }),
      //   catchError((error: HttpErrorResponse) => {
      //     file.inProgress = false;
      //     return of(`${file.FileName} upload failed.`);
      //   }))
      .subscribe((result: any) => {
        // alert(file.FileName)
        const fileNew = new ImageModel();
        fileNew.FileName = result.body.FileNameOnDisk;
        fileNew.Path = this.getUploadedFile(result.body.FilePath);
        fileNew.PageId = this.pageId;

        this.imagesService.create(fileNew).subscribe();
        if (typeof (result) === 'object') {
          // console.log(event.body);
        }
      });
  }

}
