import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/frontoffice/services/AuthenticateService';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from 'src/app/core/entities/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  opened = false;
  isLoggedIn: Observable<boolean>;                  // {1}
  isAdmin: Observable<boolean>;
  userEmail: string;
  nav: any;
  currentUser: User;

  constructor(private authService: AuthenticationService, router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.sidenav.close();

        this.currentUser = this.authService.currentUserValue;
        if (this.currentUser) {
          this.isLoggedIn = this.authService.isLoggedIn; // {2}
          return true;
        }
      }
    });
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn; // {2}
    // this.items = [
    //   { label: 'Categories' },
    //   { label: 'Sports' },
    //   { label: 'Football' },
    //   { label: 'Countries' },
    //   { label: 'Spain' },
    //   { label: 'F.C. Barcelona' },
    //   { label: 'Squad' },
    //   { label: 'Lionel Messi', url: 'https://en.wikipedia.org/wiki/Lionel_Messi', icon: 'pi pi-external-link' }
    // ];

    // this.home = { icon: 'pi pi-home' };
  }

  onLogout() {
    this.authService.logout();                      // {3}
  }

  toggle() {
    this.opened = false;
  }
}
